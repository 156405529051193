import { useEffect } from "react";

export const useClickOutside = (ref: any, cb: any) => {
  const handler = (e: any) => {
    if (ref && !ref.current?.contains(e.target)) cb();
  };

  useEffect(() => {
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);
};
