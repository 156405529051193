import React, { useEffect } from "react";
import Link from "next/link";
import { useToggle } from "@/hooks";
import {
  ProfileHeader,
  ShoppingCartHeader,
  WishlistHeader,
  Search,
  SearchMobile,
} from "@/components";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useCategoriesContext } from "@/context/CategoriesContext";
import { adminRoutes } from "@/const";

export const Header = () => {
  const router = useRouter();
  const [showSidebar, toggleSidebar, setToggleSidebar] = useToggle();
  const [showSearchBar, toggleSearchBar, setSearchBarView] = useToggle();

  const { categories } = useCategoriesContext();

  useEffect(() => {
    const handleRouteChange = () => {
      setToggleSidebar(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const isAdminRoute = adminRoutes.includes(router.pathname);
  // console.log(isAdminRoute, "is admin route");
  return (
    <>
      {/* MOBILE MENU START */}
      <header className="site__header d-lg-none">
        <div
          className="mobile-header mobile-header--sticky"
          data-sticky-mode="pullToShow"
        >
          <div className="mobile-header__panel">
            <div className="container">
              <div className="mobile-header__body">
                <button
                  className="mobile-header__menu-button"
                  onClick={toggleSidebar}
                >
                  <svg width="18px" height="14px">
                    <use xlinkHref="/styles/images/sprite.svg#menu-18x14"></use>
                  </svg>
                </button>
                <div className="nav-panel__logo">
                  <Link className="mobile-header__logo" href="/">
                    <img src="/images/logo.svg" alt="logo" />
                  </Link>
                </div>
                {/* TODO: add lazy loading for this component only on mobile view */}
                <SearchMobile
                  toggleSearchBar={toggleSearchBar}
                  showSearchBar={showSearchBar}
                  setSearchBarView={setSearchBarView}
                />

                <div className="mobile-header__indicators">
                  <div className="indicator indicator--mobile-search indicator--mobile d-md-none">
                    <button
                      className="indicator__button"
                      onClick={toggleSearchBar}
                    >
                      <span className="indicator__area">
                        <svg width="20px" height="20px">
                          <use xlinkHref="/images/sprite.svg#search-20"></use>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <WishlistHeader />
                  <ShoppingCartHeader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* MOBILE MENU END */}

      {/* DEKSTOP MENU START */}
      <header className="site__header d-lg-block d-none">
        <div className="site-header">
          <Search />
          <div className="site-header__nav-panel">
            {!isAdminRoute && (
              <div
                className="nav-panel nav-panel--sticky"
                data-sticky-mode="pullToShow"
              >
                <div className="nav-panel__container container">
                  <div className="nav-panel__row">
                    <div className="nav-panel__nav-links nav-links">
                      <ul className="nav-links__list">
                        <li className="nav-links__item nav-links__item--has-submenu">
                          <Link className="nav-links__item-link" href="/">
                            <div className="nav-links__item-body">Головна</div>
                          </Link>
                        </li>
                        {categories?.map((category) => {
                          return (
                            <li
                              key={category.id}
                              className="nav-links__item nav-links__item--has-submenu"
                            >
                              <Link
                                className={classNames(
                                  "nav-links__item-link",
                                  router.query.slug &&
                                    router.query.slug === category.slug &&
                                    "nav-links__item-link-active"
                                )}
                                href={`/products/${category.slug}`}
                              >
                                <div className="nav-links__item-body">
                                  {category.name}
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="nav-panel__indicators">
                      <div className="indicator indicator--trigger--click">
                        <button type="button" className="indicator__button">
                          <span className="indicator__area">
                            <svg
                              className="indicator__icon indicator__icon--open"
                              width="20px"
                              height="20px"
                            >
                              <use xlinkHref="/styles/images/sprite.svg#cross-20"></use>
                            </svg>
                          </span>
                        </button>
                        <div className="indicator__dropdown">
                          <div className="search search--location--indicator">
                            <div className="search__body">
                              <form className="search__form" action="">
                                <input
                                  className="search__input"
                                  name="search"
                                  placeholder="Search over 10,000 products"
                                  aria-label="Site search"
                                  type="text"
                                  autoComplete="off"
                                />
                                <button
                                  className="search__button search__button--type--submit"
                                  type="submit"
                                >
                                  <svg width="20px" height="20px">
                                    <use xlinkHref="/styles/images/sprite.svg#search-20"></use>
                                  </svg>
                                </button>
                                <div className="search__border"></div>
                              </form>
                              <div className="search__suggestions suggestions suggestions--location--indicator"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* WISHSLIST */}
                      <WishlistHeader />
                      {/* WISHSLIST END */}
                      {/* CART END */}
                      <ShoppingCartHeader />
                      {/* CART END */}
                      <ProfileHeader />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      {/* DEKSTOP MENU END */}

      {/* MOBILE SIDEBAR START */}
      <div
        className={classNames("mobilemenu", showSidebar && "mobilemenu--open")}
      >
        <div className="mobilemenu__backdrop" onClick={toggleSidebar} />
        <div className="mobilemenu__body">
          <div className="mobilemenu__header">
            <div className="mobilemenu__title">Меню</div>
            <button
              type="button"
              className="mobilemenu__close"
              onClick={toggleSidebar}
            >
              <svg width="20px" height="20px">
                <use xlinkHref="images/sprite.svg#cross-20" />
              </svg>
            </button>
          </div>
          <div className="mobilemenu__content">
            <ul className="mobile-links mobile-links--level--0">
              <li className="mobile-links__item" data-collapse-item>
                <div className="mobile-links__item-title">
                  <Link href="/" className="mobile-links__item-link">
                    Головна
                  </Link>
                </div>
              </li>
              {categories?.map((category) => {
                return (
                  <li
                    key={category.id}
                    className="mobile-links__item"
                    data-collapse-item
                  >
                    <div className="mobile-links__item-title">
                      <Link
                        className="mobile-links__item-link"
                        href={`/products/${category.slug}`}
                      >
                        {category.name}
                      </Link>
                    </div>
                  </li>
                );
              })}

              <li className="mobile-links__item" data-collapse-item>
                <div className="mobile-links__item-title">
                  <Link
                    href="/account/dashboard"
                    className="mobile-links__item-link"
                  >
                    Профіль
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* MOBILE SIDEBAR END */}
    </>
  );
};
