export const PriceSigns = {
  uah: "грн",
  usd: "$",
};

export const localStorageKeys = {
  RESEND_TIME: "resendTime",
};

export const adminRoutes = [
  "/admin/add_product_spec",
  "/admin/add_product_spec/[productId]",
];
