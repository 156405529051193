import { localStorageKeys } from "@/const";
import { useMutate } from "@/hooks";
import { FC, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";

interface ResendEmailVerificationButtonProps {
  totalSec: number;
  email: string;
}

export const ResendEmailVerificationButton: FC<
  ResendEmailVerificationButtonProps
> = ({ totalSec, email }) => {
  const { trigger: resendVerification } = useMutate(
    "/client/resend-verification/",
    "POST"
  );

  const countRef = useRef("");
  const [restart, setRestart] = useState(true);
  const [time, setTime] = useState(Date.now() + totalSec);

  const resendVerificationCode = (e: any, api: any) => {
    e.preventDefault();
    setTime(Date.now() + totalSec);
    resendVerification({
      email,
    })
      .then(() => {
        api.start();
      })
      .catch((error) => {
        toast.error("Помилка під час надсилання посилання");
      });
  };

  const renderer = ({ hours, minutes, seconds, completed, api }: any) => {
    if (completed) {
      return (
        <div
          className="btn btn-primary mt-4"
          onClick={(e) => resendVerificationCode(e, api)}
        >
          Надіслати повторно
        </div>
      );
    } else {
      const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
      const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`;

      return (
        <div>
          <span>{formattedMinutes}</span>
          <span>{" : "}</span>
          <span>{formattedSeconds}</span>
        </div>
      );
    }
  };

  useEffect(() => {
    if (restart) {
      // @ts-expect-error
      countRef?.current?.start();
    }
  }, [restart, time]);

  return (
    <Countdown
      date={time}
      renderer={renderer}
      autoStart={false}
      // @ts-expect-error
      ref={countRef}
      onTick={(value) => {
        window.localStorage.setItem(
          localStorageKeys.RESEND_TIME,
          `${value.total}`
        );
      }}
      onComplete={() => {
        window.localStorage.removeItem(localStorageKeys.RESEND_TIME);
      }}
    ></Countdown>
  );
};
