import { UseFormRegister, WatchInternal } from "react-hook-form";

interface CheckboxProps {
  id: string;
  label: string;
  register: UseFormRegister<any>;
  watch?: WatchInternal<string>;
  checked: boolean;
}

export function Checkbox({
  id,
  register,
  watch,
  checked,
  label,
}: CheckboxProps) {
  watch && watch(id);

  return (
    <label className="filter-list__item">
      <span className="filter-list__input input-check">
        <span className="input-check__body">
          <input
            className="input-check__input"
            type="checkbox"
            {...register(id)}
          />
          <span className="input-check__box" />
          {checked && (
            <svg className="input-check__icon" width="9px" height="7px">
              <use xlinkHref="/images/sprite.svg#check-9x7" />
            </svg>
          )}
        </span>
      </span>
      <span className="filter-list__title">{label}</span>
    </label>
  );
}
