import React, { FC } from "react";
import Link from "next/link";
import dayjs from "dayjs";

import { OrderDetailsStatus } from "@/const";
import { OrderHistoryProduct } from "@/types";

interface OrdersHistoryTableProps {
  orders: OrderHistoryProduct[];
}

export const OrdersHistoryTable: FC<OrdersHistoryTableProps> = ({ orders }) => {
  return (
    <div className="card-table">
      <div className="table-responsive-sm">
        <table>
          <thead>
            <tr>
              <th>Замовлення</th>
              <th>Дата</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order) => {
              return (
                <tr key={order.id}>
                  <td>
                    <Link href={`/account/order-details/${order.id}`}>
                      #{order.id}
                    </Link>
                  </td>
                  <td>{dayjs(order.created_at).format("MM/DD/YYYY")}</td>
                  <td>{OrderDetailsStatus[order.current_status]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
