import React from "react";
import Link from "next/link";
import { PriceSigns } from "@/const";
import { useUserContext } from "@/context/UserContext";
import { formatPriceWithSpaces } from "@/utils";

export const CheckoutProducts = () => {
  const { cart } = useUserContext();

  const totalSum = cart?.reduce(
    (prev: any, acc: any) => +acc.product_price * acc.amount + prev,
    0
  );

  return (
    <table className="checkout__totals">
      <thead className="checkout__totals-header">
        <tr>
          <th>Товар</th>
          <th>Всього</th>
        </tr>
      </thead>
      <tbody className="checkout__totals-products">
        {cart?.map((cartProduct) => {
          return (
            <tr key={cartProduct.product_id}>
              <td>
                <span style={{ wordBreak: "break-all" }}>
                  <Link
                    href={`/product/${cartProduct.product_id}`}
                    target="_blank"
                  >
                    {cartProduct.product_name}
                  </Link>
                </span>
                <br />
                <span>
                  Кількість: <strong>{cartProduct.amount}</strong>
                </span>
                {/* 
                <p>
                  Кількість: <strong>{cartProduct.amount}</strong>{" "}
                </p> */}
              </td>
              <td>
                {formatPriceWithSpaces(+cartProduct.product_price)}{" "}
                {PriceSigns.uah}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tbody className="checkout__totals-subtotals">
        <tr>
          <th>Всього</th>
          <td>
            {formatPriceWithSpaces(totalSum)} {PriceSigns.uah}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
