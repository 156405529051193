import { useUserContext } from "@/context/UserContext";
import Link from "next/link";
import React from "react";

export const WishlistHeader = () => {
  const { isWishlistLoading, wishlist } = useUserContext();

  if (isWishlistLoading) return <></>;

  return (
    <div className="indicator">
      <Link href="/wishlist" className="indicator__button">
        <span className="indicator__area">
          <svg width="20px" height="20px">
            <use xlinkHref="/styles/images/sprite.svg#heart-20"></use>
          </svg>
          <span className="indicator__value">{wishlist?.length || 0}</span>
        </span>
      </Link>
    </div>
  );
};
