import useSWRMutation from "swr/mutation";
import { MethodType } from "@/types";
import { AxiosError } from "axios";
import { axiosInstance } from "@/api";

type KeyType = string | null;
type KeyTypeFn = () => KeyType;
type Key = KeyType | KeyTypeFn;

// https://swr.vercel.app/docs/conditional-fetching#conditional
export const useMutate = <P, D>(
  key: Key,
  method: MethodType,
  headers?: Record<string, string>
) => {
  const actionFetcher =
    <A>(method: MethodType) =>
    (url: string, { arg }: { arg: A }) =>
      axiosInstance({
        method,
        url,
        data: arg,
        headers,
      })
        .then((val) => val.data)
        .catch((error) => {
          throw error;
        });

  return useSWRMutation<D, AxiosError, Key, P>(key, actionFetcher(method));
};
