import { contactUsValidation } from "@/const";
import { useMutate } from "@/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "..";

export const ContactUsForm = () => {
  const { trigger: sendMessage } = useMutate("/client/leave-message/", "POST");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      client_name: "",
      client_email: "",
      subject: "",
      message: "",
    },
    resolver: zodResolver(contactUsValidation),
  });

  const onSubmit = async (formValues: any) => {
    try {
      await sendMessage(formValues);
      toast.success("Повідомлення відправлено, дякуємо!");
      reset();
    } catch (error) {
      toast.error("Помилка під час відправки повідомлення");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="form-name">Ваше Ім'я</label>
          <input
            type="text"
            id="form-name"
            className="form-control"
            placeholder="Ваше Ім'я"
            {...register("client_name")}
          />
          {errors.client_name?.message && (
            <ErrorMessage message={errors.client_name?.message} />
          )}
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="form-email">Пошта</label>
          <input
            type="email"
            id="form-email"
            className="form-control"
            placeholder="Пошта"
            {...register("client_email")}
          />
          {errors.client_email?.message && (
            <ErrorMessage message={errors.client_email?.message} />
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="form-subject">Тема</label>
        <input
          type="text"
          id="form-subject"
          className="form-control"
          placeholder="Тема"
          {...register("subject")}
        />
        {errors.subject?.message && (
          <ErrorMessage message={errors.subject?.message} />
        )}
      </div>
      <div className="form-group">
        <label htmlFor="form-message">Повідомлення</label>
        <textarea
          id="form-message"
          className="form-control"
          rows={4}
          {...register("message")}
        ></textarea>
        {errors.message?.message && (
          <ErrorMessage message={errors.message?.message} />
        )}
      </div>
      <button type="submit" className="btn btn-primary">
        Відправити
      </button>
    </form>
  );
};
