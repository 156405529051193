import React, { FC, useState } from "react";
import classNames from "classnames";

import { ProductItemCatalog, EmptyData } from "@/components";
import {
  PRODUCT_VIEW_LIST_SORTING,
  PRODUCT_VIEW_LIST_VARIANTS,
  ProductProps,
} from "@/types";
import { useAuth } from "@/hooks";

interface ProductsGridProps {
  products: ProductProps[];
  toggleMobileFilters: () => void;
}

export const ProductsGrid: FC<ProductsGridProps> = ({
  products,
  toggleMobileFilters,
}) => {
  const { isLoggedIn } = useAuth();

  const [viewLayout, setViewLayout] = useState(PRODUCT_VIEW_LIST_VARIANTS.GRID);
  const [sortBy, setSortBy] = useState(PRODUCT_VIEW_LIST_SORTING.DEFAULT);

  const handleChangeViewLayout = (
    viewLayoutState: PRODUCT_VIEW_LIST_VARIANTS
  ) => {
    setViewLayout(viewLayoutState);
  };

  const renderProductsLayout = () => {
    if (!!!products.length) return <EmptyData text="Немає даних" />;

    // local sorting by selector, not being saved anywhere by that I mean in url
    if (sortBy === PRODUCT_VIEW_LIST_SORTING.DEFAULT) {
      return products.map((product) => (
        <ProductItemCatalog
          key={product.id}
          item={product}
          isLoggedIn={isLoggedIn}
        />
      ));
    }

    return products
      .sort((a, b) => {
        if (sortBy === PRODUCT_VIEW_LIST_SORTING.AZ) {
          return a.name.toUpperCase() < b.name.toUpperCase() ? 1 : -1;
        }

        if (sortBy === PRODUCT_VIEW_LIST_SORTING.ASC) {
          return +a.price - +b.price;
        }

        if (sortBy === PRODUCT_VIEW_LIST_SORTING.DESC) {
          return +b.price - +a.price;
        }
        return 0;
      })
      .map((product) => (
        <ProductItemCatalog
          key={product.id}
          item={product}
          isLoggedIn={isLoggedIn}
        />
      ));
  };

  return (
    <div className="shop-layout__content">
      <div className="block">
        <div className="products-view">
          <div className="products-view__options">
            <div className="view-options view-options--offcanvas--mobile">
              <div className="view-options__filters-button">
                <button
                  type="button"
                  className="filters-button"
                  onClick={toggleMobileFilters}
                >
                  <svg
                    className="filters-button__icon"
                    width="16px"
                    height="16px"
                  >
                    <use xlinkHref="/images/sprite.svg#filters-16" />
                  </svg>
                  <span className="filters-button__title">Фільтри</span>
                </button>
              </div>
              {/* LAYOUT BUTTONS START */}
              <div className="view-options__layout">
                <div className="layout-switcher">
                  <div className="layout-switcher__list">
                    <button
                      data-layout="grid-3-sidebar"
                      data-with-features="false"
                      title="Grid"
                      type="button"
                      className={classNames(
                        "layout-switcher__button",
                        viewLayout === "grid-3-sidebar" &&
                          "layout-switcher__button--active"
                      )}
                      onClick={() =>
                        handleChangeViewLayout(PRODUCT_VIEW_LIST_VARIANTS.GRID)
                      }
                    >
                      <svg width="16px" height="16px">
                        <use xlinkHref="/images/sprite.svg#layout-grid-16x16" />
                      </svg>
                    </button>
                    <button
                      data-layout="list"
                      data-with-features="false"
                      title="List"
                      type="button"
                      className={classNames(
                        "layout-switcher__button",
                        viewLayout === "list" &&
                          "layout-switcher__button--active"
                      )}
                      onClick={() =>
                        handleChangeViewLayout(PRODUCT_VIEW_LIST_VARIANTS.LIST)
                      }
                    >
                      <svg width="16px" height="16px">
                        <use xlinkHref="/images/sprite.svg#layout-list-16x16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {/* LAYOUT BUTTONS END */}
              <div className="view-options__divider"></div>
              <div className="view-options__control">
                <label htmlFor="">Сортувати за</label>
                <div>
                  <select
                    className="form-control form-control-sm"
                    name=""
                    id=""
                    onChange={(e) => {
                      // bullshit solution, look for refactor
                      if (
                        e.target.value === PRODUCT_VIEW_LIST_SORTING.DEFAULT
                      ) {
                        window.location.replace(window.location.href);
                      }
                      setSortBy(e.target.value as PRODUCT_VIEW_LIST_SORTING);
                    }}
                  >
                    <option value={PRODUCT_VIEW_LIST_SORTING.DEFAULT}>
                      За замовчуванням
                    </option>
                    <option value={PRODUCT_VIEW_LIST_SORTING.AZ}>
                      Ім'я (А-Я)
                    </option>
                    <option value={PRODUCT_VIEW_LIST_SORTING.ASC}>
                      Від дешевих до дорогих
                    </option>
                    <option value={PRODUCT_VIEW_LIST_SORTING.DESC}>
                      Від дорогих до дешевих
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="products-view__list products-list"
            // grid-3-sidebar
            data-layout={viewLayout}
            data-with-features="false"
            data-mobile-grid-columns="2"
          >
            <div className="products-list__body">{renderProductsLayout()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
