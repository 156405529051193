import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useMutate } from "@/hooks";
import { ProfileProps } from "@/types";

interface UpdateProfileFormProps {
  profile: ProfileProps;
  mutate: any;
}

export const UpdateProfileForm: FC<UpdateProfileFormProps> = ({
  profile,
  mutate,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...profile,
    },
  });

  const { trigger: updateProfileInfo } = useMutate("/client/info/", "PATCH");

  const onSubmit = async (formValues: any) => {
    try {
      await updateProfileInfo(formValues);

      toast.success("Дані були змінені");
      mutate();
    } catch (error) {
      toast.error("Помилка під час оновлення даних");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="col-12 col-lg-7 col-xl-6"
    >
      <div className="form-group">
        <label htmlFor="profile-first-name">Ім'я</label>
        <input
          type="text"
          className="form-control"
          id="profile-first-name"
          placeholder="Ім'я"
          {...register("first_name")}
        />
      </div>
      <div className="form-group">
        <label htmlFor="profile-last-name">Прізвище</label>
        <input
          type="text"
          className="form-control"
          id="profile-last-name"
          placeholder="Прізвище"
          {...register("last_name")}
        />
      </div>
      <div className="form-group">
        <label htmlFor="profile-phone">Мобільний номер</label>
        <input
          type="text"
          className="form-control"
          id="profile-phone"
          placeholder="Мобільний номер"
          {...register("phone")}
        />
      </div>
      <div className="form-group mt-5 mb-0">
        <button type="submit" className="btn btn-primary">
          Зберегти
        </button>
      </div>
    </form>
  );
};
