import React, { FC } from "react";

interface ProductsInfoRowProps {
  products: any[];
  title: string;
  ComponentToRender: any;
}

export const BestSellers: FC<ProductsInfoRowProps> = ({
  products,
  title,
  ComponentToRender,
}) => {
  return (
    <div
      className="block block-products block-products--layout--large-last"
      data-mobile-grid-columns="2"
    >
      <div className="container">
        <div className="block-header">
          <h3 className="block-header__title">{title}</h3>
          <div className="block-header__divider" />
        </div>
        <div className="block-products__body">
          <div className="block-products__list">
            {products.map((product) => (
              <ComponentToRender key={product.id} item={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
