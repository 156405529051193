export enum PRODUCT_VIEW_LIST_VARIANTS {
  GRID = "grid-3-sidebar",
  LIST = "list",
}

export enum PRODUCT_VIEW_LIST_SORTING {
  DEFAULT = "default",
  AZ = "a-z",
  ASC = "asc",
  DESC = "desc",
}
