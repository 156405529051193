import React, { FC } from "react";

import { useToggle } from "@/hooks";
import classNames from "classnames";

interface FilterToggleBlockProps {
  title: string;
  children: any;
  preOpened?: boolean;
  withTitle?: boolean;
}

export const FilterToggleBlock: FC<FilterToggleBlockProps> = ({
  title,
  children,
  preOpened = false,
  withTitle = true,
}) => {
  const [showFilter, toggleFilter] = useToggle(preOpened);

  return (
    <div className="widget-filters__item">
      <div
        className={classNames("filter", showFilter && "filter--opened")}
        data-collapse-item
      >
        {withTitle && (
          <button
            type="button"
            className="filter__title"
            data-collapse-trigger
            onClick={toggleFilter}
          >
            {title}
            <svg className="filter__arrow" width="12px" height="7px">
              <use xlinkHref="images/sprite.svg#arrow-rounded-down-12x7" />
            </svg>
          </button>
        )}
        <div className="filter__body" data-collapse-content>
          <div className="filter__container">{children}</div>
        </div>
      </div>
    </div>
  );
};
