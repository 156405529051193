import { Nullable } from ".";

export interface ProfileProps {
  username: string;
  first_name: string;
  last_name: string;
  phone: Nullable<string>;
  email: string;
  is_staff: boolean;
}

export enum DeliveryMethods {
  NP_WAREHOUSE = "np_warehouse",
  ADDRESS_DELIVERY = "address_delivery",
}
