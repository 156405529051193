import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AxiosError } from "axios";
import { axiosInstance } from "@/api";

type KeyType = string | null;
type KeyTypeFn = () => KeyType;
type KeyTypeObj<P = any> = { url: string; args?: P };
type Key = KeyType | KeyTypeFn | KeyTypeObj;

export function axiosRequest<TData, TParams = void>(
  url: string,
  params?: TParams
) {
  return axiosInstance
    .get<TData>(url, { params: params ? { ...params } : undefined })
    .then((val) => val.data);
}

// https://swr.vercel.app/docs/conditional-fetching#conditional
// https://swr.vercel.app/docs/typescript#basic-usage
export const useApi = <T, P = void>(
  key: Key,
  config?: SWRConfiguration,
  params?: P
): SWRResponse<T, AxiosError> => {
  const getFetcher = (key: string | KeyTypeObj<P>) => {
    // TODO: temporary solution, change all instances for object approach across the app
    if (typeof key === "string") return axiosRequest<T, P>(key, params);
    return axiosRequest<T, P>(key.url, key.args);
  };

  return useSWR<T, AxiosError>(key, getFetcher, config);
};

// Use useMultipleParallelRequestsApi if request MUST NOT fail if one of the promises rejected

export const useMultipleParallelRequestsApi = <T, P = void>(
  keys: string[],
  config?: SWRConfiguration,
  params?: P
): SWRResponse<Array<T | AxiosError>, AxiosError> => {
  const getAllFetcher = (urls: string[]): Promise<T[]> =>
    Promise.allSettled<T>(
      urls.map((url) => axiosRequest<T, P>(url, params))
    ).then((responses) =>
      responses.map((response) =>
        response.status === "fulfilled" ? response.value : response.reason
      )
    );

  return useSWR<Array<T | AxiosError>, AxiosError>(keys, getAllFetcher, config);
};
