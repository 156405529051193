import type { AppProps } from "next/app";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import { SWRConfig } from "swr/_internal";
import type { SWRConfiguration } from "swr";
import { Slide, ToastContainer } from "react-toastify";

import { UserContextProvider } from "@/context/UserContext";
import { Footer, Header } from "@/components";
import { CategoriesContextProvider } from "@/context/CategoriesContext";

import "dayjs/locale/uk";
import "react-toastify/dist/ReactToastify.css";
import "photoswipe/dist/photoswipe.css";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const config: SWRConfiguration = {
    errorRetryCount: 2,
    revalidateOnFocus: false,
  };

  if (pageProps.fallback) config.fallback = pageProps.fallback;

  return (
    <>
      <Head>
        <title>VODOTECH</title>
        <meta name="description" content="Vodotech is the best!" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/x-icon" href="/images/favicon.png" />
      </Head>
      <SessionProvider session={session}>
        <SWRConfig value={config}>
          <UserContextProvider>
            <CategoriesContextProvider>
              <div className="site">
                <Header />
                <div className="site__body">
                  <Component {...pageProps} />
                </div>
                <Footer />
              </div>
            </CategoriesContextProvider>
          </UserContextProvider>
          <ToastContainer
            transition={Slide}
            autoClose={2000}
            icon={false}
            closeButton={false}
            className="notification"
            draggable={false}
          />
        </SWRConfig>
      </SessionProvider>
    </>
  );
}
