import { useAuth, useClickOutside, useToggle } from "@/hooks";
import classNames from "classnames";
import { DropdownAuthForm } from "@/components";
import React, { useEffect, useRef } from "react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { useUserContext } from "@/context/UserContext";
import { useRouter } from "next/router";

export const ProfileHeader = () => {
  const router = useRouter();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { isLoggedIn } = useAuth();
  const { profile } = useUserContext();

  const [showMenu, toggleMenu, setShowMenu] = useToggle();

  const closeMenu = () => setShowMenu(false);

  useClickOutside(menuRef, () => closeMenu());

  useEffect(() => {
    const handleRouteChange = () => {
      closeMenu();
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <div
      className={classNames(
        "indicator indicator--trigger--click ",
        showMenu && "indicator--open indicator--display"
      )}
      ref={menuRef}
    >
      <div className="indicator__button" onClick={toggleMenu}>
        <span className="indicator__area">
          <svg width="20px" height="20px">
            <use xlinkHref="/styles/images/sprite.svg#person-20" />
          </svg>
        </span>
      </div>
      <div className="indicator__dropdown">
        <div className="account-menu">
          {!isLoggedIn ? (
            <DropdownAuthForm />
          ) : (
            <>
              <div className="account-menu__divider" />
              <Link href="/account/dashboard" className="account-menu__user">
                <div className="account-menu__user-avatar">
                  <img src="/styles/images/avatars/avatar-3.jpg" alt="" />
                </div>
                <div className="account-menu__user-info">
                  <div className="account-menu__user-name">
                    {profile?.username}
                  </div>
                  <div className="account-menu__user-email">
                    {profile?.email}
                  </div>
                </div>
              </Link>
              <div className="account-menu__divider" />
              <ul className="account-menu__links">
                <li>
                  <Link href="/account/edit">Редагувати профіль</Link>
                </li>
                <li>
                  <Link href="/account/orders-history">Історія Замовлень</Link>
                </li>
                <li>
                  <Link href="/account/addresses">Адреси</Link>
                </li>
                <li>
                  <Link href="/account/update-password">Пароль</Link>
                </li>
              </ul>
              <div className="account-menu__divider" />
              <ul className="account-menu__links">
                <li>
                  <a href="#" onClick={() => signOut()}>
                    Вийти
                  </a>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
