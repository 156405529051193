import { PaymentMethods } from ".";

export enum OrderStatus {
  PENDING = "pending",
  SHIPPED = "shipped",
  REJECTED = "rejected",
  PAID = "paid",
  COMPLETED = "completed",
}

export interface OrderProductProps {
  product: {
    id: number;
    name: string;
  };
  amount: number;
  fixed_price: string;
}

export interface OrderHistoryProduct {
  id: number;
  payment_method: PaymentMethods;
  created_at: string;
  current_status: OrderStatus;
}

export interface OrderDeliveryAddressProps {
  type: string;
  np_warehouse: number;
  delivery_address: string;
  first_name: string;
  second_name: string;
  phone_number: string;
}

export interface OrderStatusHistoryProps {
  status: OrderStatus;
  changed_at: string;
}

export interface OrderHistoryDetail {
  id: number;
  client: number;
  session: number;
  payment_method: PaymentMethods;
  order_products: OrderProductProps[];
  delivery_address: OrderDeliveryAddressProps;
  status_history: OrderStatusHistoryProps[];
}

export interface SavedAddressProps {
  id: number;
  type: string;
  delivery_method: string;
  np_warehouse: number;
  delivery_address: string;
  first_name: string;
  second_name: string;
  phone_number: string;
  client: number;
}
