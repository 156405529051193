import { useApi, useAuth, useMutate } from "@/hooks";
import {
  CartProduct,
  CategoryHeaderProps,
  Nullable,
  ProductCartAction,
  ProfileProps,
} from "@/types";
import { useRouter } from "next/router";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { toast } from "react-toastify";

interface CategoriesContextProps {
  categories: CategoryHeaderProps[] | undefined;
  isCategoriesLoading: boolean;
}

interface UserProviderProps {
  children: ReactNode;
}

const initialFavContext = {
  categories: [],
  isCategoriesLoading: true,
};

export const CategoriesContext =
  createContext<CategoriesContextProps>(initialFavContext);

export const CategoriesContextProvider: FC<UserProviderProps> = ({
  children,
}) => {
  const { data: categories, isLoading: isCategoriesLoading } = useApi<
    CategoryHeaderProps[]
  >("/products/categories/");

  const value = useMemo(
    () => ({
      categories,
      isCategoriesLoading,
    }),
    [categories, isCategoriesLoading]
  );

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => useContext(CategoriesContext);
