import React, { FC } from "react";
import Link from "next/link";
import NextImage from "next/image";
import { ImageProductProps, ProductProps } from "@/types";
import classNames from "classnames";
import { PriceSigns } from "@/const";
import { useUserContext } from "@/context/UserContext";
import { Gallery, Item, useGallery } from "react-photoswipe-gallery";
import sanitizeHtml from "sanitize-html";
import htmlParser from "html-react-parser";
import { formatPriceWithSpaces } from "@/utils";

interface ProductItemCatalogProps {
  item: ProductProps;
  isLoggedIn: boolean;
}

const ProductPreviewButton: FC<{ images: ImageProductProps[] }> = ({
  images,
}) => {
  const { open } = useGallery();

  const handleClickPreviewImageSlider = () => {
    open(0);
  };

  return (
    <>
      <button
        className="product-card__quickview"
        type="button"
        onClick={handleClickPreviewImageSlider}
      >
        <svg width="16px" height="16px">
          <use xlinkHref="/images/sprite.svg#zoom-in-24" />
        </svg>
        <span className="fake-svg-icon" />
      </button>
      <div style={{ display: "none" }}>
        {images.map((img, i) => (
          <Item
            original={`https://vodotech.com.ua${img.image}`}
            width={600}
            height={600}
            key={i}
          >
            {({ ref, open }) => (
              <div onClick={open}>
                <img ref={ref} src={`https://vodotech.com.ua${img.image}`} />
              </div>
            )}
          </Item>
        ))}
      </div>
    </>
  );
};

export const ProductItemCatalog: FC<ProductItemCatalogProps> = ({ item }) => {
  const { handleAddToCart, handleAddToWishlist, cart, wishlist } =
    useUserContext();

  const { id, name, description, price, amount, title_image, images } = item;

  const isInStock = amount >= 1;
  const isInShoppingCart = !!cart?.find(
    (cartItem: any) => item?.id == cartItem.product_id
  );
  const isInWishlist = !!wishlist?.find(
    (wishlistItem: any) => item?.id == wishlistItem.product_id
  );

  return (
    <div className="products-list__item">
      <div className="product-card product-card--hidden-actions ">
        <Gallery>
          <ProductPreviewButton images={images} />
        </Gallery>

        <div className="product-card__image product-image">
          <Link href={`/product/${id}`} className="product-image__body">
            <NextImage
              className="product-image__img"
              src={`https://vodotech.com.ua/${title_image?.image}`}
              alt={title_image?.image_type as string}
              height={128}
              width={128}
            />
          </Link>
        </div>
        <div className="product-card__info">
          <div className="product-card__name">
            <Link href={`/product/${id}`}>{name}</Link>
          </div>

          {description && (
            <ul className="product-card__features-list">
              <li>
                <div>
                  {description &&
                    htmlParser(sanitizeHtml(description.slice(0, 250)))}
                </div>
              </li>
            </ul>
          )}
        </div>
        <div className="product-card__actions">
          <div className="product-card__availability">
            Доступність:{" "}
            <span className={classNames(isInStock && "text-success")}>
              {isInStock ? "В наявності" : "Немає на складі"}
            </span>
          </div>
          <div className="product-card__prices">
            {formatPriceWithSpaces(+price)} {PriceSigns.uah}
          </div>
          <div className="product-card__buttons">
            <button
              className="btn btn-primary product-card__addtocart"
              type="button"
              onClick={() => handleAddToCart(item.id as number)}
              // style={{ height: "45px" }}
            >
              {isInShoppingCart ? "Вже у кошику" : "Додати в кошик"}
            </button>
            <button
              className={classNames(
                "btn btn-secondary product-card__addtocart product-card__addtocart--list",
                isInShoppingCart && "saved"
              )}
              type="button"
              onClick={() => handleAddToCart(item.id as number)}
            >
              {isInShoppingCart ? "Вже у кошику" : "Додати до кошика"}
            </button>
            <button
              className={classNames(
                "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                isInWishlist && "saved"
              )}
              type="button"
              onClick={() => handleAddToWishlist(item.id)}
            >
              <svg width="16px" height="16px">
                <use xlinkHref="/images/sprite.svg#wishlist-16" />
              </svg>
              <span className="fake-svg-icon fake-svg-icon--wishlist-16" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
