import React from "react";

export const Pagination = () => {
  return (
    <div className="products-view__pagination">
      <ul className="pagination justify-content-center">
        <li className="page-item disabled">
          <a
            className="page-link page-link--with-arrow"
            href=""
            aria-label="Previous"
          >
            <svg
              className="page-link__arrow page-link__arrow--left"
              aria-hidden="true"
              width="8px"
              height="13px"
            >
              <use xlinkHref="/styles/images/sprite.svg#arrow-rounded-left-8x13"></use>
            </svg>
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="">
            1
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link" href="">
            2 <span className="sr-only">(current)</span>
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="">
            3
          </a>
        </li>
        <li className="page-item">
          <a
            className="page-link page-link--with-arrow"
            href=""
            aria-label="Next"
          >
            <svg
              className="page-link__arrow page-link__arrow--right"
              aria-hidden="true"
              width="8px"
              height="13px"
            >
              <use xlinkHref="/styles/images/sprite.svg#arrow-rounded-right-8x13"></use>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};
