import { DeliveryMethods } from "@/types";
import * as z from "zod";

const ONLY_CHARACTES = /^[A-Za-zа-яА-Я]+(\s)?$/;
// const PHONE_REGEX = /^\(?\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/;
const PHONE_REGEX = /^(\+?38)?\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/;

export const loginValidation = z.object({
  email: z
    .string()
    .min(1, { message: "Необхідно вказати адресу електронної пошти" })
    .email({
      message: "Має бути дійсна електронна адреса",
    }),
  password: z.string().min(1, { message: "Необхідно ввести пароль" }),
});

export const signUpValidation = z.object({
  username: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: "Має містити лише літери без цифр",
    }),
  email: z
    .string()
    .min(1, { message: "Необхідно вказати адресу електронної пошти" })
    .email({
      message: "Має бути дійсна електронна адреса",
    }),
  password: z
    .string()
    .min(6, { message: "Пароль має бути не менше 6 символів" }),
});

export const forgotPasswordEmailValidation = z.object({
  email: z
    .string()
    .min(1, { message: "Необхідно вказати адресу електронної пошти" })
    .email({
      message: "Має бути дійсна електронна адреса",
    }),
});

export const forgotPasswordValidation = z
  .object({
    new_password: z
      .string()
      .min(6, { message: "Пароль має бути не менше 6 символів" }),
    confirm_password: z
      .string()
      .min(1, { message: "Потрібно підтвердити пароль" }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    path: ["confirm_password"],
    message: "Пароль не збігається",
  });

export const contactUsValidation = z.object({
  client_name: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" }),
  client_email: z
    .string()
    .min(1, { message: "Необхідно вказати адресу електронної пошти" })
    .email({
      message: "Має бути дійсна електронна адреса",
    }),
  subject: z.string().min(1, { message: "Тема письма необхідна" }),
  message: z.string().min(1, { message: "Повідомлення необхідно" }),
});

export const shopingCartValidation = z.object({
  first_name: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: "Має містити лише літери без цифр",
    }),
  second_name: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: "Має містити лише літери без цифр",
    }),
  area: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "Це поле є обов'язковим" }
    )
    .required({
      label: true,
      value: true,
    }),
  // district: z.object({ label: z.string(), value: z.string() }).optional(),
  settlement: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "Це поле є обов'язковим" }
    )
    .required({
      label: true,
      value: true,
    }),
  warehouse: z
    .object(
      {
        label: z.string(),
        value: z.number(),
      },
      { required_error: "Це поле є обов'язковим" }
    )
    .required({
      label: true,
      value: true,
    }),
  phone_number: z
    .string()
    .min(1, { message: "Телефон обов'язковий" })
    .refine((value) => PHONE_REGEX.test(value), {
      message: "Недійсний номер телефону",
    }),
  payment_method: z.string().min(1, { message: "Оберіть спосіб оплати" }),
  notes: z.string().optional().or(z.literal("")),
  checkoutTerms: z.literal(true, {
    errorMap: () => ({
      message: "Ви повинні прийняти Загальні положення та умови",
    }),
  }),
});

export const createAddressFormValidation = z.object({
  first_name: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" }),
  second_name: z
    .string()
    .min(2, { message: "Має дорівнювати або більше 2 символів" }),
  phone_number: z
    .string()
    .min(1, { message: "Телефон обов'язковий" })
    .refine((value) => PHONE_REGEX.test(value), {
      message: "Недійсний номер телефону",
    }),
  delivery_method: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: "Це поле є обов'язковим" }
    )
    .required({
      label: true,
      value: true,
    }),
});
