import React, { FC } from "react";
import classNames from "classnames";
import Link from "next/link";
import NextImage from "next/image";

import { ProductProps } from "@/types";
import { formatPriceWithSpaces } from "@/utils";
import { PriceSigns } from "@/const";
import { useUserContext } from "@/context/UserContext";

interface ProductListItemProps {
  item: ProductProps;
}

export const ProductListItem: FC<ProductListItemProps> = ({ item }) => {
  const { handleAddToCart, handleAddToWishlist, cart, wishlist } =
    useUserContext();

  const isInStock = item.amount >= 1;
  const isInShoppingCart = !!cart?.find(
    (cartItem: any) => item?.id == cartItem.product_id
  );
  const isInWishlist = !!wishlist?.find(
    (wishlistItem: any) => item?.id == wishlistItem.product_id
  );

  return (
    <div className="block-products__list-item">
      <div className="product-card product-card--hidden-actions">
        <div className="product-card__badges-list"></div>
        <div className="product-card__image product-image">
          <Link href={`/product/${item.id}`} className="product-image__body">
            <NextImage
              className="product-image__img"
              src={`https://vodotech.com.ua${item.title_image?.image}`}
              height={200}
              width={200}
              alt={item.name}
            />
          </Link>
        </div>
        <div className="product-card__info">
          <div className="product-card__name">
            <Link href={`/product/${item.id}`}>{item.name}</Link>
          </div>
        </div>
        <div className="product-card__actions">
          <div className="product-card__availability">
            Доступність:{" "}
            <span className={classNames(isInStock && "text-success")}>
              {isInStock ? "В наявності" : "Немає на складі"}
            </span>
          </div>
          <div className="product-card__prices">
            {item.orig_price ? (
              <>
                <span className="product-card__new-price">
                  {item?.price && formatPriceWithSpaces(+item?.price)}{" "}
                  {PriceSigns.uah}
                </span>{" "}
                <span className="product-card__old-price">
                  {item?.price && formatPriceWithSpaces(+item?.orig_price)}{" "}
                  {PriceSigns.uah}
                </span>
              </>
            ) : (
              <>
                {item?.price && formatPriceWithSpaces(+item?.price)}{" "}
                {PriceSigns.uah}
              </>
            )}
          </div>
          <div className="product-card__buttons">
            <button
              className="btn btn-primary product-card__addtocart"
              type="button"
              onClick={() => handleAddToCart(item.id)}
            >
              {isInShoppingCart ? "Вже у кошику" : "Додати в кошик"}
            </button>
            <button
              className="btn btn-secondary product-card__addtocart product-card__addtocart--list"
              type="button"
              onClick={() => handleAddToCart(item.id)}
            >
              {isInShoppingCart ? "Вже у кошику" : "Додати в кошик"}
            </button>
            <button
              className={classNames(
                "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                isInWishlist && "saved"
              )}
              type="button"
              onClick={() => handleAddToWishlist(item.id)}
            >
              <svg width="16px" height="16px">
                <use xlinkHref="/styles/images/sprite.svg#wishlist-16" />
              </svg>
              <span className="fake-svg-icon fake-svg-icon--wishlist-16" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
