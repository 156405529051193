import Link from "next/link";
import NextImage from "next/image";
import React, { FC } from "react";
import classNames from "classnames";

import { PriceSigns } from "@/const";
import { ProductProps } from "@/types";
import { formatPriceWithSpaces } from "@/utils";

interface SearchSuggestionsProps {
  products: ProductProps[];
  isMobile?: boolean;
}

export const SearchSuggestions: FC<SearchSuggestionsProps> = ({
  products,
  isMobile = false,
}) => {
  return (
    <div
      className={classNames(
        "search__suggestions suggestions",
        isMobile
          ? "suggestions--location--mobile-header"
          : "suggestions--location--header"
      )}
    >
      <ul className="suggestions__list">
        {products.map((product) => {
          return (
            <li className="suggestions__item" key={product.id}>
              <div className="suggestions__item-image product-image">
                <div className="product-image__body">
                  <NextImage
                    className="product-image__img"
                    src={`https://vodotech.com.ua/${product.title_image?.image}`}
                    alt={product.name}
                    height={44}
                    width={44}
                  />
                </div>
              </div>
              <div className="suggestions__item-info">
                <Link
                  className="suggestions__item-name"
                  href={`/product/${product.id}`}
                >
                  {product.name}
                </Link>
              </div>
              <div className="suggestions__item-price">
                {formatPriceWithSpaces(+product.price)} {PriceSigns.uah}
              </div>
              {/* <div className="suggestions__item-actions">
                <button
                  type="button"
                  title="Add to cart"
                  className="btn btn-primary btn-sm btn-svg-icon"
                >
                  <svg width="16px" height="16px">
                    <use xlinkHref="/images/sprite.svg#cart-16"></use>
                  </svg>
                </button>
              </div> */}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
