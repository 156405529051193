import React, { FC } from "react";
// @ts-expect-error
import RangeSlider from "react-range-slider-input";

import { SpecificationFilterProps } from "@/types";
import { formatPriceWithSpaces } from "@/utils";
import { useFormContext } from "react-hook-form";

interface FilterRangeBlock {
  filter: SpecificationFilterProps;
  textId: string;
}

export const FilterRangeBlock: FC<FilterRangeBlock> = ({ filter, textId }) => {
  const { getValues, setValue } = useFormContext();

  const minValue = filter.values[0];
  const maxValue = filter.values.slice(-1)[0];

  return (
    <div className="filter-price__container mt-3">
      <RangeSlider
        min={Math.floor(minValue) || 0}
        max={Math.floor(maxValue) || 100}
        value={[
          Math.floor(getValues(textId)?.split(",")[0] || minValue),
          Math.floor(getValues(textId)?.split(",")[1] || maxValue),
        ]}
        onInput={(value: number[]) => {
          setValue(textId, `${value[0]},${value[1]}`);
        }}
      />
      <div className="filter-price" data-to="1130">
        <div className="filter-price__slider" />
        <div className="filter-price__title">
          <span className="filter-price__min-value">
            {formatPriceWithSpaces(minValue)} {filter.unit_short_name}
          </span>
          {getValues(textId) && (
            <span>
              {" "}
              {getValues(textId).split(",")[0]} -{" "}
              {getValues(textId).split(",")[1]}{" "}
            </span>
          )}
          <span className="filter-price__max-value">
            {formatPriceWithSpaces(maxValue)} {filter.unit_short_name}
          </span>
        </div>
      </div>
    </div>
  );
};
