import React from "react";
import { Rating } from "..";

export const ProductReviewComment = () => {
  return (
    <li className="reviews-list__item">
      <div className="review">
        <div className="review__avatar">
          <img src="/styles/images/avatars/avatar-1.jpg" alt="" />
        </div>
        <div className="review__content">
          <div className="review__author">Samantha Smith</div>
          <div className="review__rating">
            <div className="rating">
              <Rating />
            </div>
          </div>
          <div className="review__text">
            Phasellus id mattis nulla. Mauris velit nisi, imperdiet vitae
            sodales in, maximus ut lectus. Vivamus commodo scelerisque lacus, at
            porttitor dui iaculis id. Curabitur imperdiet ultrices fermentum.
          </div>
          <div className="review__date">27 May, 2018</div>
        </div>
      </div>
    </li>
  );
};
