export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export type MethodType = "POST" | "DELETE" | "PATCH" | "PUT";

export type Nullable<T> = T | null;

export enum ContentType {
  GENERAL = "general",
  SUPPORT = "support",
}

export enum ContentInfoType {
  ABOUT = "about",
  PRIVACY_POLICY = "privacy_policy",
  DELIVERY_RULES = "delivery_rules",
}

export interface CompanyPhoneProps {
  number: string;
  description: string;
  phone_type: ContentType;
}

export interface CompanyEmailProps {
  email_address: string;
  description: string;
  email_type: ContentType;
}

export interface CompanyAddressProps {
  city: string;
  street: string;
  building: string;
  description: string;
}

export interface CompanyInfoProps {
  title: string;
  type: ContentInfoType;
  text: string;
}
export interface CompanyInfoDataProps {
  name: string;
  phones: CompanyPhoneProps[];
  email_addresses: CompanyEmailProps[];
  addresses: CompanyAddressProps[];
  infos: CompanyInfoProps[];
}
