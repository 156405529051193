import React, { FC } from "react";

interface RegisterWithSocialAuthProps {
  isSignIn?: boolean;
}

export const RegisterWithSocialAuth: FC<RegisterWithSocialAuthProps> = ({
  isSignIn = false,
}) => {
  return (
    <div className="flex-grow-1 mb-3">
      <div
        className="card-body"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h5 className="card-title">
          {isSignIn
            ? "Увійти за допомогаю соцмереж"
            : "Реєстрація за допомогаю соцмереж"}
        </h5>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            window.open(
              "https://vodotech.com.ua/api/v1/client/social-auth/login/google-oauth2/"
            );
          }}
        >
          <img
            style={{ maxWidth: "40px" }}
            src="/images/googlelogo.png"
            alt="google logo"
          />
          <p className="mb-0 ml-2">Google</p>
        </div>
      </div>
    </div>
  );
};
