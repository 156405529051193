import { Nullable } from ".";

export interface CategoryProps {
  name: string;
  parent: number;
  slug: string;
}

export enum ProductCartAction {
  ADD = "add",
  REMOVE = "remove",
}

export interface CategoryHeaderProps {
  id: number;
  name: string;
  slug: string;
  is_abstract: boolean;
  is_leaf: boolean;
  subcategories: CategoryHeaderProps[];
}

export interface ImageProductProps {
  image: string;
  image_type: string;
}

export interface PriceRange {
  min_price: number;
  max_price: number;
}

export enum SpecificationUnitType {
  INT = "int",
  BOOL = "bool",
  TEXT = "text",
}

export interface SpecificationFilterProps {
  title: string;
  unit_type: SpecificationUnitType;
  filter_name: string;
  unit_short_name: Nullable<string>;
  unit_full_name: Nullable<string>;
  description: string;
  values: any[];
}

export interface SpeficationValueProps {
  id: number;
  title: string;
  unit_type: SpecificationUnitType;
  filter_name: string;
  unit_short_name: string;
  unit_full_name: Nullable<string>;
  description: string;
  values: number[] | boolean[] | string[];
}

export interface SpecificationProps {
  specification: SpeficationValueProps;
  value: number | boolean | string;
}

export interface ProductProps {
  id: number;
  name: string;
  description: string;
  amount: number;
  category: CategoryProps;
  manufacturer: number;
  price: string;
  orig_price: Nullable<string>;
  images: ImageProductProps[];
  title_image: Nullable<ImageProductProps>;
}

export interface ManufacturerDetailProps {
  id: number;
  name: string;
  country: string;
  city: string;
}

export interface ProductTagProps {
  id: number;
  name: string;
}

export interface ProductDetailsProps {
  id: number;
  name: string;
  description: string;
  amount: number;
  category: CategoryProps;
  manufacturer_detail: ManufacturerDetailProps;
  price: string;
  images: ImageProductProps[];
  title_image: Nullable<ImageProductProps>;
  specifications_values: SpecificationProps[];
  tags: ProductTagProps[];
}

export enum ActiveTab {
  DESCRIPTION = "description",
  SPECIFICATION = "specification",
  REVIEWS = "reviews",
}

export interface ManufacturerProps {
  id: number;
  name: string;
  country: string;
  city: string;
}

export interface CartProduct {
  product_id: string;
  product_name: string;
  product_price: string;
  amount: number;
  product_title_image: string;
  added_to_cart: string;
}

export interface WishlistSavedItemProp {
  product_id: string;
  product_name: string;
  product_title_image: string;
  product_amount: string;
  product_price: string;
}
