import React, { FC, Fragment } from "react";
import { useForm } from "react-hook-form";

import { useApi, useMutate, useToggle } from "@/hooks";
import {
  ProductDetailsProps,
  SpecificationProps,
  SpecificationUnitType,
} from "@/types";

interface AdminSpecProductProps {
  product: ProductDetailsProps;
}
// [
//   0.5,
//   0.9,
//   1.1,
//   1.2,
//   1.4,
//   1.5,
//   2
// ]
const RenderInputComponent = ({
  specUnit,
  productId,
}: {
  specUnit: SpecificationProps;
  productId: number;
}) => {
  const { trigger: updateSpec } = useMutate(`/products/${productId}`, "POST");

  const { register, handleSubmit } = useForm({
    defaultValues: {},
  });

  const onSubmit = (value: any) => {
    console.log(value, "value on submit.");
    console.log(specUnit, "spec unit?");
    const dataToUpdate = {
      id: productId,
      data: {
        specifications_to_update: [
          {
            specification_id: specUnit.specification.id,
            value: value[specUnit.specification.title],
          },
        ],
      },
    };

    console.log(dataToUpdate, "dataToUpdate");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {SpecificationUnitType.BOOL === specUnit.specification.unit_type && (
        <div>
          <select>
            {specUnit.specification.values.map((val, i) => {
              const value = `${val}`;
              return (
                <option key={i} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          <button type="submit">update SPEC</button>
        </div>
      )}

      {SpecificationUnitType.INT === specUnit.specification.unit_type && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {specUnit.specification.values.map((val) => {
            return (
              <div key={Math.random()}>
                <p>{val}</p>
              </div>
            );
          })}
          {/* @ts-expect-error */}
          <input {...register(specUnit.specification.title)} type="number" />
          <button type="submit">додати новий SPEC</button>
        </div>
      )}

      {SpecificationUnitType.TEXT === specUnit.specification.unit_type && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {specUnit.specification.values.map((val) => {
            return (
              <div key={val as string}>
                <p>{val}</p>
              </div>
            );
          })}
          {/* @ts-expect-error */}
          <input {...register(specUnit.specification.title)} type="text" />
          <button type="submit">додати новий SPEC</button>
        </div>
      )}
    </form>
  );
};

const ProductSpecEditForm = ({ data }: { data: ProductDetailsProps }) => {
  console.log(data, " data component with form");

  return (
    <div>
      {data.specifications_values.map((spec, i) => {
        return (
          <div key={i * +spec.value} style={{ marginBottom: "20px" }}>
            <h5>
              {spec.specification.title} ---{" "}
              {spec.specification.unit_short_name} ---{" "}
              {spec.specification.unit_type}
            </h5>

            <RenderInputComponent specUnit={spec} productId={data.id} />
            {/* {spec.value} */}
          </div>
        );
      })}
    </div>
  );
};

export const AdminSpecProduct: FC<AdminSpecProductProps> = ({ product }) => {
  const [isClicked, toggleClickProduct] = useToggle();

  const { data } = useApi<ProductDetailsProps>(
    isClicked ? `/products/${product.id}/` : null
  );

  return (
    <Fragment>
      <li className="mb-5" onClick={toggleClickProduct}>
        <p>{product.name}</p>
      </li>
      {data && <ProductSpecEditForm data={data} />}
    </Fragment>
  );
};
