import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Loader, SearchSuggestions } from "@/components";
import { useRouter } from "next/router";
import debounce from "lodash.debounce";

import { useApi, useClickOutside } from "@/hooks";
import { PriceRange, ProductProps } from "@/types";
import { MIN_CHARACTERS_LENGHT_FOR_SEARCH } from "@/const";

interface SearchMobileProps {
  showSearchBar: boolean;
  toggleSearchBar: () => void;
  setSearchBarView: (value: boolean) => void;
}

export const SearchMobile: FC<SearchMobileProps> = ({
  showSearchBar,
  toggleSearchBar,
  setSearchBarView,
}) => {
  const router = useRouter();
  const searchHeaderRef = useRef<HTMLDivElement>(null);

  const [search_pattern, setSearch_pattern] = useState(
    router.query.search_pattern || ""
  );
  const [inputValue, setInputValue] = useState(
    router.query.search_pattern || ""
  );
  const [typeLoading, setTypeLoading] = useState(false);

  const closeSuggestionsItems = () => showSearchBar && setSearchBarView(false);

  useClickOutside(searchHeaderRef, () => closeSuggestionsItems());

  const {
    data: productsData,
    mutate: refreshProductsRequest,
    isValidating,
  } = useApi<
    {
      products: ProductProps[];
      price_range: PriceRange;
    },
    any
  >(
    search_pattern.length >= MIN_CHARACTERS_LENGHT_FOR_SEARCH
      ? `/products/list/`
      : null,
    {},
    {
      search_pattern: search_pattern,
      ...router.query,
    }
  );

  const onSubmit = (e: any) => {
    e.preventDefault();

    refreshProductsRequest();
  };

  const debouncedFn = useCallback(
    debounce((value) => {
      setSearch_pattern(value);
      setTimeout(
        () =>
          refreshProductsRequest().finally(() => {
            setTypeLoading(false);
          }),
        10
      );
    }, 2000),
    []
  );

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedFn(value);
  };

  useEffect(() => {
    const handleRouteChange = (nextRoute: string) => {
      closeSuggestionsItems();

      if (!nextRoute.includes("search_pattern")) {
        setSearch_pattern("");
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <div
      className={classNames(
        "search search--location--mobile-header mobile-header__search",
        showSearchBar && "search--suggestions-open mobile-header__search--open",
        productsData &&
          !!productsData.products.length &&
          "search--has-suggestions"
      )}
      ref={searchHeaderRef}
    >
      <div className="search__body">
        <form className="search__form" onSubmit={onSubmit}>
          <input
            className="search__input"
            placeholder="Шукати товар"
            aria-label="Site search"
            type="text"
            onChange={handleInputChange}
            value={inputValue}
          />
          {(typeLoading || isValidating) && (
            <Loader style={{ width: "30px" }} />
          )}
          <button
            className="search__button search__button--type--submit"
            type="submit"
          >
            <svg width="20px" height="20px">
              <use xlinkHref="/images/sprite.svg#search-20" />
            </svg>
          </button>
          <button
            className="search__button search__button--type--close"
            type="button"
            onClick={toggleSearchBar}
          >
            <svg width="20px" height="20px">
              <use xlinkHref="/images/sprite.svg#cross-20" />
            </svg>
          </button>
          <div className="search__border" />
        </form>
        {productsData?.products && (
          <SearchSuggestions products={productsData?.products} isMobile />
        )}
      </div>
    </div>
  );
};
