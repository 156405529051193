import Link from "next/link";
import React, { FC } from "react";
import NextImage from "next/image";

import { PriceSigns } from "@/const";
import { useUserContext } from "@/context/UserContext";
import { formatPriceWithSpaces } from "@/utils";

interface DropdownCartItemProps {
  id: number;
  image: string;
  title: string;
  description?: any;
  amount: number;
  price: number;
}

export const DropdownCartItem: FC<DropdownCartItemProps> = ({
  id,
  image,
  title,
  amount,
  price,
}) => {
  const { handleAddToCart } = useUserContext();

  const handleDeleteItem = () => {
    handleAddToCart(id);
  };

  return (
    <div className="dropcart__product">
      <div className="product-image dropcart__product-image">
        <Link href={`/product/${id}`} className="product-image__body">
          <NextImage
            className="product-image__img"
            src={image}
            alt={title}
            height={70}
            width={70}
          />
        </Link>
      </div>
      <div className="dropcart__product-info">
        <div className="dropcart__product-name">
          <Link href={`/product/${id}`}>{title}</Link>
        </div>
        <div className="dropcart__product-meta">
          <span className="dropcart__product-quantity">{amount}</span> ×{" "}
          <span className="dropcart__product-price">
            {formatPriceWithSpaces(price)} {PriceSigns.uah}
          </span>
        </div>
      </div>
      <button
        type="button"
        className="dropcart__product-remove btn btn-light btn-sm btn-svg-icon"
        onClick={handleDeleteItem}
      >
        <svg width="10px" height="10px">
          <use xlinkHref="/styles/images/sprite.svg#cross-10" />
        </svg>
      </button>
    </div>
  );
};
