import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";

type ToggleValueFunction = () => void;
type SetValueFunction = Dispatch<SetStateAction<boolean>>;
type UseToggle = [boolean, ToggleValueFunction, SetValueFunction];

export const useToggle = (defaultValue?: boolean): UseToggle => {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const toggleValue = useCallback<ToggleValueFunction>(
    () => setValue((prevState) => !prevState),
    []
  );

  return [value, toggleValue, setValue];
};
