import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import debounce from "lodash.debounce";

import { Loader, SearchSuggestions } from "@/components";
import { useApi, useClickOutside } from "@/hooks";
import {
  CompanyInfoDataProps,
  ContentType,
  PriceRange,
  ProductProps,
} from "@/types";
import { MIN_CHARACTERS_LENGHT_FOR_SEARCH, adminRoutes } from "@/const";

export const Search = () => {
  const router = useRouter();
  const searchHeaderRef = useRef<HTMLDivElement>(null);

  const isAdminRoute = adminRoutes.includes(router.pathname);

  const { data: companyInfoData } =
    useApi<CompanyInfoDataProps[]>("/company/info/");

  const [search_pattern, setSearch_pattern] = useState(
    router.query.search_pattern || ""
  );
  const [inputValue, setInputValue] = useState(
    router.query.search_pattern || ""
  );
  const [typeLoading, setTypeLoading] = useState(false);

  const [isFocusedInput, setIsFocusedInput] = useState(false);

  const closeSuggestionsItems = () => setIsFocusedInput(false);

  useClickOutside(searchHeaderRef, () => closeSuggestionsItems());

  const supportEmail =
    companyInfoData &&
    companyInfoData[0].email_addresses.filter(
      (email) => email.email_type === ContentType.SUPPORT
    )[0].email_address;

  const supportPhone =
    companyInfoData &&
    companyInfoData[0].phones.filter(
      (phone) => phone.phone_type === ContentType.SUPPORT
    )[0].number;

  const {
    data: productsData,
    mutate: refreshProductsRequest,
    isValidating,
  } = useApi<
    {
      products: ProductProps[];
      price_range: PriceRange;
    },
    any
  >(
    search_pattern.length >= MIN_CHARACTERS_LENGHT_FOR_SEARCH
      ? `/products/list/?search_pattern=${search_pattern}`
      : null,
    {
      revalidateOnFocus: false,
    },
    {
      // search_pattern: search_pattern,
      ...router.query,
    }
  );

  const onSubmit = (e: any) => {
    e.preventDefault();

    refreshProductsRequest();
  };

  const debouncedFn = useCallback(
    debounce((value) => {
      setSearch_pattern(value);
      setTimeout(
        () =>
          refreshProductsRequest().finally(() => {
            setTypeLoading(false);
          }),
        10
      );
    }, 1000),
    []
  );

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    setTypeLoading(true);
    debouncedFn(value);
  };

  useEffect(() => {
    const handleRouteChange = (nextRoute: string) => {
      closeSuggestionsItems();

      if (!nextRoute.includes("search_pattern")) {
        setSearch_pattern("");
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <div className="site-header__middle container">
      <div className="site-header__logo">
        <Link href="/">
          <img
            className="site-header-logo"
            src="/images/vodotech_logo.svg"
            alt="vodotech logo"
          />
        </Link>
      </div>
      {!isAdminRoute && (
        <div className="site-header__search" ref={searchHeaderRef}>
          <div
            className={classNames(
              "search search--location--header",
              (productsData as any) &&
                !!(productsData as any).products.length &&
                "search--suggestions-open",
              (productsData as any) &&
                !!(productsData as any).products.length &&
                "search--has-suggestions"
            )}
          >
            <div className="search__body">
              <form className="search__form" onSubmit={onSubmit}>
                <input
                  className="search__input"
                  placeholder="Шукати товар"
                  aria-label="Site search"
                  type="text"
                  onFocus={() => {
                    setIsFocusedInput(true);
                  }}
                  onChange={handleInputChange}
                  value={inputValue}
                />
                {(typeLoading || isValidating) && (
                  <Loader style={{ width: "30px" }} />
                )}
                <button
                  className="search__button search__button--type--submit"
                  type="submit"
                >
                  <svg width="20px" height="20px">
                    <use xlinkHref="/images/sprite.svg#search-20" />
                  </svg>
                </button>
                <div className="search__border"></div>
              </form>
              {productsData?.products && isFocusedInput && (
                <SearchSuggestions products={productsData?.products} />
              )}
            </div>
          </div>
        </div>
      )}
      {!isAdminRoute && (
        <div className="site-header__phone">
          <a
            href={`mailto:${supportEmail}`}
            className="site-header__phone-title"
          >
            {supportEmail}
          </a>
          <div className="site-header__phone-number">
            <a style={{ color: "#212121" }} href={`tel:${supportPhone}`}>
              {supportPhone}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
