import { useSession } from "next-auth/react";

import { AuthStatus } from "@/types";

export const useAuth = () => {
  const { status, data: session } = useSession();

  const isLoggedIn = status === AuthStatus.AUTHENTICATED;
  const isAuthLoading = status === AuthStatus.LOADING;

  return {
    isLoggedIn,
    isAuthLoading,
    session,
  };
};
