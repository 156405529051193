import React, { FC } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
// @ts-expect-error
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useFormContext } from "react-hook-form";

import {
  ManufacturerProps,
  PriceRange,
  SpecificationFilterProps,
} from "@/types";
import { PriceSigns, FILTERS_KEYS_RANGE } from "@/const";
import { formatPriceWithSpaces } from "@/utils";
import { FilterToggleBlock, FilterRangeBlock } from "@/components";

interface SidebarFiltersProps {
  showMobileFilters: boolean;
  toggleMobileFilters: () => void;
  manufacturers: ManufacturerProps[];
  priceRange: PriceRange;
  checkboxesFilters: SpecificationFilterProps[];
  textFilters: SpecificationFilterProps[];
  numberFilters: SpecificationFilterProps[];
}

export const SidebarFilters: FC<SidebarFiltersProps> = ({
  showMobileFilters,
  toggleMobileFilters,
  manufacturers,
  priceRange,
  checkboxesFilters,
  textFilters,
  numberFilters,
}) => {
  const router = useRouter();
  const { setValue, getValues, reset, unregister } = useFormContext();

  return (
    <div className="shop-layout__sidebar">
      <div
        className={classNames(
          "block block-sidebar block-sidebar--offcanvas--mobile",
          showMobileFilters && "block-sidebar--open"
        )}
      >
        <div
          className="block-sidebar__backdrop"
          onClick={toggleMobileFilters}
        />
        <div className="block-sidebar__body">
          <div className="block-sidebar__header">
            <div className="block-sidebar__title">Фільтри</div>
            <button
              className="block-sidebar__close"
              type="button"
              onClick={toggleMobileFilters}
            >
              <svg width="20px" height="20px">
                <use xlinkHref="/images/sprite.svg#cross-20" />
              </svg>
            </button>
          </div>
          <div className="block-sidebar__item">
            <div
              className="widget-filters widget widget-filters--offcanvas--mobile"
              data-collapse
              data-collapse-opened-classname="filter--opened"
            >
              <h4 className="widget-filters__title widget__title">Фільтри</h4>

              <div className="widget-filters__list">
                <FilterToggleBlock
                  title="Виробники"
                  preOpened
                >
                  <div className="filter-categories">
                    <ul className="filter-categories__list">
                      {manufacturers?.map((item) => {
                        const checkedValues: string =
                          getValues("manufacturer_id");

                        const splitted = checkedValues
                          ? checkedValues.split(",")
                          : [];

                        const isChecked = splitted.some(
                          (value: string) => +value === item.id
                        );

                        return (
                          <label key={item.id} className="filter-list__item">
                            <span className="filter-list__input input-check">
                              <span className="input-check__body">
                                <input
                                  className="input-check__input"
                                  type="checkbox"
                                  value={item.id}
                                  checked={isChecked}
                                  name="manufacturer_id"
                                  onChange={(inputValue) => {
                                    const checked = inputValue.target.checked;

                                    if (checked) {
                                      setValue(
                                        "manufacturer_id",
                                        [...splitted, item.id].join(",")
                                      );
                                    } else {
                                      const valueToBeSaved = splitted
                                        .filter((value) => +value !== item.id)
                                        .join(",");

                                      !!valueToBeSaved.length
                                        ? setValue(
                                            "manufacturer_id",
                                            valueToBeSaved
                                          )
                                        : unregister("manufacturer_id");
                                    }
                                  }}
                                />
                                <span className="input-check__box" />
                                {isChecked && (
                                  <svg
                                    className="input-check__icon"
                                    width="9px"
                                    height="7px"
                                  >
                                    <use xlinkHref="/images/sprite.svg#check-9x7" />
                                  </svg>
                                )}
                              </span>
                            </span>
                            <span className="filter-list__title">
                              {item.name}
                            </span>
                          </label>
                        );
                      })}
                    </ul>
                  </div>
                </FilterToggleBlock>

                <FilterToggleBlock title="Ціна" preOpened>
                  <RangeSlider
                    min={Math.floor(priceRange.min_price) || 0}
                    max={Math.floor(priceRange.max_price) || 100}
                    value={[
                      Math.floor(
                        getValues("min_price") || priceRange.min_price
                      ),
                      Math.floor(
                        getValues("max_price") || priceRange.max_price
                      ),
                    ]}
                    onInput={(value: number[]) => {
                      setValue("min_price", value[0]);
                      setValue("max_price", value[1]);
                    }}
                  />
                  <div className="filter-price" data-to="1130">
                    <div className="filter-price__slider"></div>
                    <div className="filter-price__title">
                      Ціна:{" "}
                      <span className="filter-price__min-value">
                        {formatPriceWithSpaces(
                          getValues("min_price") || priceRange.min_price
                        )}
                      </span>{" "}
                      {PriceSigns.uah} –{" "}
                      <span className="filter-price__max-value">
                        {formatPriceWithSpaces(
                          getValues("max_price") || priceRange.max_price
                        )}
                      </span>{" "}
                      {PriceSigns.uah}
                    </div>
                  </div>
                </FilterToggleBlock>

                {!!checkboxesFilters.length && (
                  <FilterToggleBlock title="" preOpened withTitle={false}>
                    {checkboxesFilters.map((checkboxFilter) => {
                      const checkboxId = `spec__${checkboxFilter.filter_name}__${checkboxFilter.unit_type}`;
                      const checked = getValues(checkboxId);

                      return (
                        <label key={checkboxId} className="filter-list__item">
                          <span className="filter-list__input input-check">
                            <span className="input-check__body">
                              <input
                                className="input-check__input"
                                type="checkbox"
                                checked={checked || false}
                                onChange={(inputValue) => {
                                  const checked = inputValue.target.checked;

                                  checked
                                    ? setValue(checkboxId, checked)
                                    : unregister(checkboxId);
                                }}
                              />
                              <span className="input-check__box" />
                              {checked && (
                                <svg
                                  className="input-check__icon"
                                  width="9px"
                                  height="7px"
                                >
                                  <use xlinkHref="/images/sprite.svg#check-9x7" />
                                </svg>
                              )}
                            </span>
                          </span>
                          <span className="filter-list__title">
                            {checkboxFilter.title}
                          </span>
                        </label>
                      );
                    })}
                  </FilterToggleBlock>
                )}

                {!!textFilters.length &&
                  textFilters.map((textFilter) => {
                    const textId = `spec__${textFilter.filter_name}__${textFilter.unit_type}`;
                    const isSavedInURL = !!router.query[textId];

                    return (
                      <div key={textFilter.title}>
                        <FilterToggleBlock
                          title={textFilter.title}
                          preOpened={isSavedInURL}
                        >
                          {textFilter.values.map((value) => {
                            const checkedValues = getValues(textId);

                            const splitted = checkedValues
                              ? checkedValues.split(",")
                              : [];

                            const isChecked = splitted.some(
                              (checkedVal: string) => checkedVal === value
                            );

                            return (
                              <label key={value} className="filter-list__item">
                                <span className="filter-list__input input-check">
                                  <span className="input-check__body">
                                    <input
                                      className="input-check__input"
                                      type="checkbox"
                                      value={value}
                                      name={textId}
                                      checked={isChecked}
                                      onChange={(inputValue) => {
                                        const checked =
                                          inputValue.target.checked;

                                        if (checked) {
                                          setValue(
                                            textId,
                                            [...splitted, value].join(",")
                                          );
                                        } else {
                                          const valueToBeSaved = splitted
                                            .filter((val: any) => val !== value)
                                            .join(",");

                                          !!valueToBeSaved.length
                                            ? setValue(textId, valueToBeSaved)
                                            : unregister(textId);
                                        }
                                      }}
                                    />
                                    <span className="input-check__box" />
                                    {isChecked && (
                                      <svg
                                        className="input-check__icon"
                                        width="9px"
                                        height="7px"
                                      >
                                        <use xlinkHref="/images/sprite.svg#check-9x7" />
                                      </svg>
                                    )}
                                  </span>
                                </span>
                                <span className="filter-list__title">{`${value} ${
                                  textFilter.unit_short_name
                                    ? textFilter.unit_short_name
                                    : ""
                                }`}</span>
                              </label>
                            );
                          })}
                        </FilterToggleBlock>
                      </div>
                    );
                  })}

                {!!numberFilters.length &&
                  numberFilters.map((numberFilters) => {
                    const textId = `spec__${numberFilters.filter_name}__${numberFilters.unit_type}`;
                    const isSavedInURL = !!router.query[textId];

                    return (
                      <div key={numberFilters.title}>
                        <FilterToggleBlock
                          title={numberFilters.title}
                          preOpened={isSavedInURL}
                        >
                          {FILTERS_KEYS_RANGE.some(
                            (key) => key === numberFilters.filter_name
                          ) ? (
                            <FilterRangeBlock
                              filter={numberFilters}
                              textId={textId}
                            />
                          ) : (
                            numberFilters.values.map((value) => {
                              const checkedValues = getValues(textId);

                              const splitted = checkedValues
                                ? checkedValues.split(",")
                                : [];

                              const isChecked = splitted.some(
                                (checkedVal: string) => +checkedVal === value
                              );

                              return (
                                <label
                                  key={value}
                                  className="filter-list__item"
                                >
                                  <span className="filter-list__input input-check">
                                    <span className="input-check__body">
                                      <input
                                        className="input-check__input"
                                        type="checkbox"
                                        value={value}
                                        checked={isChecked}
                                        name={textId}
                                        onChange={(inputValue) => {
                                          const checked =
                                            inputValue.target.checked;

                                          if (checked) {
                                            setValue(
                                              textId,
                                              [...splitted, value].join(",")
                                            );
                                          } else {
                                            const valueToBeSaved = splitted
                                              .filter(
                                                (val: any) => +val !== value
                                              )
                                              .join(",");

                                            !!valueToBeSaved.length
                                              ? setValue(textId, valueToBeSaved)
                                              : unregister(textId);
                                          }
                                        }}
                                      />
                                      <span className="input-check__box" />
                                      {isChecked && (
                                        <svg
                                          className="input-check__icon"
                                          width="9px"
                                          height="7px"
                                        >
                                          <use xlinkHref="/images/sprite.svg#check-9x7" />
                                        </svg>
                                      )}
                                    </span>
                                  </span>
                                  <span className="filter-list__title">{`${value} ${
                                    numberFilters.unit_short_name
                                      ? numberFilters.unit_short_name
                                      : ""
                                  }`}</span>
                                </label>
                              );
                            })
                          )}
                        </FilterToggleBlock>
                      </div>
                    );
                  })}
              </div>

              <div className="widget-filters__actions d-flex">
                <button className="btn btn-primary btn-sm" type="submit">
                  Фільтрувати
                </button>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => {
                    // removed params and sets default url for this page
                    reset();
                    router.replace(router.asPath.split("?")[0]);
                  }}
                  type="button"
                >
                  Скинути
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
