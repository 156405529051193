import React, { FC } from "react";
import Link from "next/link";

interface Breadcrumb {
  title: string;
  href: string;
}

interface BreadcrumbsProps {
  links: Breadcrumb[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ links }) => {
  const withoutLast = links.slice(0, links.length - 1);
  const activeElement = links.slice(-1);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {withoutLast.map((link, i) => {
          return (
            <li key={link.title} className="breadcrumb-item">
              <Link href={link.href}>{link.title}</Link>
              <svg className="breadcrumb-arrow" width="6px" height="9px">
                <use xlinkHref="/styles/images/sprite.svg#arrow-rounded-right-6x9"></use>
              </svg>
            </li>
          );
        })}
        <li key={"lastElement"} className="breadcrumb-item active">
          {activeElement[0].title}
        </li>
      </ol>
    </nav>
  );
};
