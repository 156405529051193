import React, { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { signOut } from "next-auth/react";
import classNames from "classnames";

interface AccountWrapper {
  children: any;
}

export const AccountWrapper: FC<AccountWrapper> = ({ children }) => {
  const router = useRouter();

  const handleLogout = () => {
    signOut();
  };

  return (
    <div className="block mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3 d-flex">
            <div className="account-nav flex-grow-1">
              <h4 className="account-nav__title">Навігація</h4>
              <ul>
                <li
                  className={classNames(
                    "account-nav__item",
                    router.route.includes("dashboard") &&
                      "account-nav__item--active"
                  )}
                >
                  <Link href="/account/dashboard">Панель</Link>
                </li>
                <li
                  className={classNames(
                    "account-nav__item",
                    router.route.includes("edit") && "account-nav__item--active"
                  )}
                >
                  <Link href="/account/edit">Редагувати профіль</Link>
                </li>
                <li
                  className={classNames(
                    "account-nav__item",
                    router.route.includes("order") &&
                      "account-nav__item--active"
                  )}
                >
                  <Link href="/account/orders-history">Історія Замовлень</Link>
                </li>
                <li
                  className={classNames(
                    "account-nav__item",
                    router.route.includes("addresses") &&
                      "account-nav__item--active"
                  )}
                >
                  <Link href="/account/addresses">Адреси</Link>
                </li>
                <li
                  className={classNames(
                    "account-nav__item",
                    router.route.includes("password") &&
                      "account-nav__item--active"
                  )}
                >
                  <Link href="/account/update-password">Пароль</Link>
                </li>
                <li className={classNames("account-nav__item")}>
                  <p onClick={handleLogout}>Вийти</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-9 mt-4 mt-lg-0">{children}</div>
        </div>
      </div>
    </div>
  );
};
