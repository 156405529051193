import React, { FC } from "react";
import Link from "next/link";

import { WishlistSavedItemProp } from "@/types";

import classNames from "classnames";
import { PriceSigns } from "@/const";
import { useUserContext } from "@/context/UserContext";
import { formatPriceWithSpaces } from "@/utils";

interface WishlistRowProps {
  item: WishlistSavedItemProp;
}

export const WishlistRow: FC<WishlistRowProps> = ({ item }) => {
  const { cart, handleAddToCart, handleAddToWishlist } = useUserContext();

  const {
    product_id,
    product_name,
    product_price,
    product_amount,
    product_title_image,
  } = item;
  const isInStock = +product_amount >= 1;

  const isInShoppingCart = !!cart?.find(
    (cartItem: any) => product_id == cartItem.product_id
  );

  return (
    <tr className="wishlist__row">
      <td className="wishlist__column wishlist__column--image">
        <div className="product-image">
          <Link
            href={`/product/${product_id}`}
            target="_blank"
            className="product-image__body"
          >
            <img
              className="product-image__img"
              src={`https://vodotech.com.ua${product_title_image}`}
              alt={product_name}
            />
          </Link>
        </div>
      </td>
      <td className="wishlist__column wishlist__column--product">
        <Link
          href={`/product/${product_id}`}
          className="wishlist__product-name"
          target="_blank"
        >
          {product_name}
        </Link>
      </td>
      <td className="wishlist__column wishlist__column--stock">
        <div
          className={classNames(
            "badge",
            isInStock ? "badge-success" : "badge-info"
          )}
        >
          {isInStock ? "В наявності" : "Відсутне"}
        </div>
      </td>
      <td className="wishlist__column wishlist__column--price">
        {formatPriceWithSpaces(+product_price)} {PriceSigns.uah}
      </td>
      <td className="wishlist__column wishlist__column--tocart">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => handleAddToCart(product_id)}
        >
          {isInShoppingCart ? "Вже у кошику" : "Додати до кошика"}
        </button>
      </td>
      <td className="wishlist__column wishlist__column--remove">
        <button
          type="button"
          className="btn btn-light btn-sm btn-svg-icon"
          onClick={() => handleAddToWishlist(product_id)}
        >
          <svg width="12px" height="12px">
            <use xlinkHref="/styles/images/sprite.svg#cross-12"></use>
          </svg>
        </button>
      </td>
    </tr>
  );
};
