import React from "react";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import Link from "next/link";

import { zodResolver } from "@hookform/resolvers/zod";
import { loginValidation } from "@/const";
import { ErrorMessage } from "..";
import { toast } from "react-toastify";

export const DropdownAuthForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (formValue: any) => {
    try {
      // generate once visits website, and pass it here and in headers
      await signIn("credentials", {
        ...formValue,
      });
    } catch (error) {
      toast.error("Помилка під час входу до кабінету");
    } finally {
      clearErrors();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="account-menu__form">
      <div className="account-menu__form-title">
        Увійдіть до свого облікового запису
      </div>
      <div className="form-group">
        <label htmlFor="header-signin-email" className="sr-only">
          Пошта
        </label>
        <input
          id="header-signin-email"
          type="email"
          className="form-control form-control-sm"
          placeholder="Адреса електронної пошти"
          required
          {...register("email")}
        />
        {errors.email?.message && (
          <ErrorMessage message={errors.email?.message} />
        )}
      </div>
      <div className="form-group">
        <label htmlFor="header-signin-password" className="sr-only">
          Пароль
        </label>
        <div className="account-menu__form-forgot">
          <input
            id="header-signin-password"
            type="password"
            className="form-control form-control-sm"
            placeholder="Пароль"
            required
            {...register("password")}
          />
          <Link
            href="/account/forgot-password"
            className="account-menu__form-forgot-link"
          >
            Забули?
          </Link>
        </div>
        {errors.password?.message && (
          <ErrorMessage message={errors.password?.message} />
        )}
      </div>
      <div className="form-group account-menu__form-button">
        <button type="submit" className="btn btn-primary btn-sm">
          Увійти
        </button>
      </div>
      <p className="text-center">або</p>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          window.open(
            "https://vodotech.com.ua/api/v1/client/social-auth/login/google-oauth2/"
          );
        }}
      >
        <img
          style={{ maxWidth: "40px" }}
          src="/images/googlelogo.png"
          alt="google logo"
        />
        <p className="mb-0 ml-2">Google</p>
      </div>
      <div className="account-menu__form-link mt-3">
        <Link href="/account/register">Створити аккаунт</Link>
      </div>
    </form>
  );
};
