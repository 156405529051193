import React from "react";

import Link from "next/link";

export const EmptyCart = () => {
  return (
    <>
      <div className="block-empty__body">
        <div className="block-empty__message">Ваш кошик пустий!</div>
        <div className="block-empty__actions">
          <Link className="btn btn-primary btn-sm" href="/">
            Продовжити перегляд
          </Link>
        </div>
      </div>
    </>
  );
};
