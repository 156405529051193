import { useApi, useAuth, useMutate } from "@/hooks";
import {
  CartProduct,
  Nullable,
  ProductCartAction,
  ProfileProps,
} from "@/types";
import { useRouter } from "next/router";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { toast } from "react-toastify";

interface UserContext {
  wishlist: any;
  isWishlistLoading: boolean;
  profile: Nullable<ProfileProps | undefined>;
  profileLoading: boolean;
  cart: CartProduct[] | undefined;
  isCartLoading: boolean;
  handleAddToCart: (id: string | number, amount?: number) => void;
  handleAddToWishlist: (id: string | number) => void;
  mutateCartData: () => void;
}

interface UserProviderProps {
  children: ReactNode;
}

const initialFavContext = {
  wishlist: null,
  isWishlistLoading: true,
  profile: null,
  profileLoading: true,
  cart: [],
  isCartLoading: true,
  handleAddToCart: () => {},
  handleAddToWishlist: () => {},
  mutateCartData: () => {},
};

export const UserContext = createContext<UserContext>(initialFavContext);

export const UserContextProvider: FC<UserProviderProps> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  const {
    data: wishlist,
    isLoading: isWishlistLoading,
    error: wishlistError,
    mutate: mutateWishlist,
  } = useApi<any[]>(isLoggedIn ? "/client/favourites" : null, {
    errorRetryCount: 0,
  });

  const { data: profile, isLoading: profileLoading } = useApi<ProfileProps>(
    isLoggedIn ? "/client/info" : null
  );
  const {
    data: cart,
    isLoading: isCartLoading,
    mutate: mutateCartData,
  } = useApi<CartProduct[]>("/orders/cart");

  const { trigger: addToFavourites } = useMutate(`/client/favourites/`, "POST");
  const { trigger: cartAction } = useMutate(`/orders/cart/`, "POST");

  const handleAddToWishlist = async (id: string | number) => {
    if (isLoggedIn) {
      const isInWishlist = !!wishlist?.find(
        (wishlistItem: any) => id == wishlistItem.product_id
      );

      try {
        await addToFavourites(
          {
            product_id: id,
            option: isInWishlist
              ? ProductCartAction.REMOVE
              : ProductCartAction.ADD,
          },
          {
            rollbackOnError: true,
          }
        );
        toast.success(
          isInWishlist ? "Видалено з обраних" : "Додано до обраних"
        );
        mutateWishlist();
      } catch (error) {
        toast.error(
          isInWishlist
            ? "Помилка під час видалення"
            : "Помилка під час додавання"
        );
      }
    } else {
      router.push("/account/login");
    }
  };

  const handleAddToCart = async (id: string | number, amount: number = 1) => {
    const isInShoppingCart = !!cart?.find(
      (cartItem: any) => id == cartItem.product_id
    );

    try {
      await cartAction(
        {
          product_id: id,
          option: isInShoppingCart
            ? ProductCartAction.REMOVE
            : ProductCartAction.ADD,
          amount,
        },
        {
          rollbackOnError: true,
        }
      );

      mutateCartData();
      toast.success(
        isInShoppingCart ? "Видаленно з кошика" : "Додано до кошика"
      );
    } catch (error) {
      toast.error(
        isInShoppingCart
          ? "Помилка під час видалення"
          : "Помилка під час додавання"
      );
    }
  };

  const value = useMemo(
    () => ({
      wishlist,
      isWishlistLoading,
      profile,
      profileLoading,
      cart,
      isCartLoading,
      handleAddToCart,
      handleAddToWishlist,
      mutateCartData,
    }),
    [
      wishlist,
      isWishlistLoading,
      profile,
      profileLoading,
      cart,
      isCartLoading,
      handleAddToCart,
      handleAddToWishlist,
      mutateCartData,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
