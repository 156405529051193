import Link from "next/link";
import React from "react";

export const PopularCategories = () => {
  return (
    <div className="block block--highlighted block-categories block-categories--layout--compact">
      <div className="container">
        <div className="block-header">
          <h3 className="block-header__title">Popular Categories</h3>
          <div className="block-header__divider"></div>
        </div>
        <div className="block-categories__list">
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-1.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Power Tools</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <Link href="">Screwdrivers</Link>
                  </li>
                  <li>
                    <Link href="">Milling Cutters</Link>
                  </li>
                  <li>
                    <Link href="">Sanding Machines</Link>
                  </li>
                  <li>
                    <Link href="">Wrenches</Link>
                  </li>
                  <li>
                    <Link href="">Drills</Link>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">572 Products</div>
              </div>
            </div>
          </div>
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-2.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Hand Tools</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <a href="">Screwdrivers</a>
                  </li>
                  <li>
                    <a href="">Hammers</a>
                  </li>
                  <li>
                    <a href="">Spanners</a>
                  </li>
                  <li>
                    <a href="">Handsaws</a>
                  </li>
                  <li>
                    <a href="">Paint Tools</a>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">134 Products</div>
              </div>
            </div>
          </div>
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-4.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Machine Tools</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <a href="">Lathes</a>
                  </li>
                  <li>
                    <a href="">Milling Machines</a>
                  </li>
                  <li>
                    <a href="">Grinding Machines</a>
                  </li>
                  <li>
                    <a href="">CNC Machines</a>
                  </li>
                  <li>
                    <a href="">Sharpening Machines</a>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">301 Products</div>
              </div>
            </div>
          </div>
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-3.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Power Machinery</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <a href="">Generators</a>
                  </li>
                  <li>
                    <a href="">Compressors</a>
                  </li>
                  <li>
                    <a href="">Winches</a>
                  </li>
                  <li>
                    <a href="">Plasma Cutting</a>
                  </li>
                  <li>
                    <a href="">Electric Motors</a>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">79 Products</div>
              </div>
            </div>
          </div>
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-5.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Measurement</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <a href="">Tape Measure</a>
                  </li>
                  <li>
                    <a href="">Theodolites</a>
                  </li>
                  <li>
                    <a href="">Thermal Imagers</a>
                  </li>
                  <li>
                    <a href="">Calipers</a>
                  </li>
                  <li>
                    <a href="">Levels</a>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">366 Products</div>
              </div>
            </div>
          </div>
          <div className="block-categories__item category-card category-card--layout--compact">
            <div className="category-card__body">
              <div className="category-card__image">
                <a href="">
                  <img src="/styles/images/categories/category-6.jpg" alt="" />
                </a>
              </div>
              <div className="category-card__content">
                <div className="category-card__name">
                  <a href="">Clothes and PPE</a>
                </div>
                <ul className="category-card__links">
                  <li>
                    <a href="">Winter Workwear</a>
                  </li>
                  <li>
                    <a href="">Summer Workwear</a>
                  </li>
                  <li>
                    <a href="">Helmets</a>
                  </li>
                  <li>
                    <a href="">Belts and Bags</a>
                  </li>
                  <li>
                    <a href="">Work Shoes</a>
                  </li>
                </ul>
                <div className="category-card__all">
                  <a href="">Show All</a>
                </div>
                <div className="category-card__products">81 Products</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
