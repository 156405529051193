import Link from "next/link";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { useClickOutside, useToggle } from "@/hooks";
import { DropdownCartItem } from "@/components";
import { useUserContext } from "@/context/UserContext";
import { PriceSigns } from "@/const";
import { formatPriceWithSpaces } from "@/utils";

export const ShoppingCartHeader = () => {
  const router = useRouter();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showCartMenu, toggleCartMenu, setShowDropdown] = useToggle();

  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const { isCartLoading, cart } = useUserContext();

  useEffect(() => {
    const handleRouteChange = () => {
      setShowDropdown(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  if (isCartLoading) return <></>;

  const renderCartContent = () => {
    if (!cart?.length)
      return (
        <div className="dropcart__products-list">
          <div className="dropcart__product-name">Ваш кошик пустий</div>
        </div>
      );

    const totalSum = cart.reduce(
      (prev: any, acc: any) => +acc.product_price * acc.amount + prev,
      0
    );

    return (
      <>
        <div className="dropcart__products-list">
          {cart.map((item: any) => (
            <DropdownCartItem
              key={item.product_id}
              image={`https://vodotech.com.ua/${item.product_title_image}`}
              id={item.product_id}
              title={item.product_name}
              amount={item.amount}
              price={item.product_price}
            />
          ))}
        </div>
        <div className="dropcart__totals">
          <table>
            <thead>
              <tr>
                <th></th>
              </tr>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <th>Subtotal</th>
                <td>$5,877.00</td>
              </tr>
              <tr>
                <th>Shipping</th>
                <td>$25.00</td>
              </tr> */}
              <tr>
                <th>Всього</th>
                <td>
                  {formatPriceWithSpaces(totalSum)} {PriceSigns.uah}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="dropcart__buttons">
          <Link className="btn btn-secondary" href="/cart">
            Дивитись кошик
          </Link>
          <Link className="btn btn-primary" href="/checkout">
            Оплата
          </Link>
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames(
        "indicator indicator--trigger--click",
        showCartMenu && "indicator--display indicator--open"
      )}
      ref={dropdownRef}
    >
      <div className="indicator__button" onClick={toggleCartMenu}>
        <span className="indicator__area">
          <svg width="20px" height="20px">
            <use xlinkHref="/styles/images/sprite.svg#cart-20" />
          </svg>
          <span className="indicator__value">{cart?.length}</span>
        </span>
      </div>
      <div className="indicator__dropdown ">
        <div className="dropcart dropcart--style--dropdown">
          <div className="dropcart__body">{renderCartContent()}</div>
        </div>
      </div>
    </div>
  );
};
