import React from "react";

// TODO: add logic here
export const Rating = () => {
  return (
    <div className="rating__body">
      <svg
        className="rating__star rating__star--active"
        width="13px"
        height="12px"
      >
        <g className="rating__fill">
          <use xlinkHref="/styles/images/sprite.svg#star-normal"></use>
        </g>
        <g className="rating__stroke">
          <use xlinkHref="/styles/images/sprite.svg#star-normal-stroke"></use>
        </g>
      </svg>
      <div className="rating__star rating__star--only-edge rating__star--active">
        <div className="rating__fill">
          <div className="fake-svg-icon"></div>
        </div>
        <div className="rating__stroke">
          <div className="fake-svg-icon"></div>
        </div>
      </div>
      <svg
        className="rating__star rating__star--active"
        width="13px"
        height="12px"
      >
        <g className="rating__fill">
          <use xlinkHref="/styles/images/sprite.svg#star-normal"></use>
        </g>
        <g className="rating__stroke">
          <use xlinkHref="/styles/images/sprite.svg#star-normal-stroke"></use>
        </g>
      </svg>
      <div className="rating__star rating__star--only-edge rating__star--active">
        <div className="rating__fill">
          <div className="fake-svg-icon"></div>
        </div>
        <div className="rating__stroke">
          <div className="fake-svg-icon"></div>
        </div>
      </div>
      <svg
        className="rating__star rating__star--active"
        width="13px"
        height="12px"
      >
        <g className="rating__fill">
          <use xlinkHref="/styles/images/sprite.svg#star-normal"></use>
        </g>
        <g className="rating__stroke">
          <use xlinkHref="/styles/images/sprite.svg#star-normal-stroke"></use>
        </g>
      </svg>
      <div className="rating__star rating__star--only-edge rating__star--active">
        <div className="rating__fill">
          <div className="fake-svg-icon"></div>
        </div>
        <div className="rating__stroke">
          <div className="fake-svg-icon"></div>
        </div>
      </div>
      <svg
        className="rating__star rating__star--active"
        width="13px"
        height="12px"
      >
        <g className="rating__fill">
          <use xlinkHref="/styles/images/sprite.svg#star-normal"></use>
        </g>
        <g className="rating__stroke">
          <use xlinkHref="/styles/images/sprite.svg#star-normal-stroke"></use>
        </g>
      </svg>
      <div className="rating__star rating__star--only-edge rating__star--active">
        <div className="rating__fill">
          <div className="fake-svg-icon"></div>
        </div>
        <div className="rating__stroke">
          <div className="fake-svg-icon"></div>
        </div>
      </div>
      <svg className="rating__star " width="13px" height="12px">
        <g className="rating__fill">
          <use xlinkHref="/styles/images/sprite.svg#star-normal"></use>
        </g>
        <g className="rating__stroke">
          <use xlinkHref="/styles/images/sprite.svg#star-normal-stroke"></use>
        </g>
      </svg>
      <div className="rating__star rating__star--only-edge ">
        <div className="rating__fill">
          <div className="fake-svg-icon"></div>
        </div>
        <div className="rating__stroke">
          <div className="fake-svg-icon"></div>
        </div>
      </div>
    </div>
  );
};
