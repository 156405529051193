import Link from "next/link";
import React, { FC } from "react";

import { PriceSigns } from "@/const";
import { CartProduct } from "@/types";
import { CartTableRow } from "..";
import { formatPriceWithSpaces } from "@/utils";

interface CartTableProps {
  cart: CartProduct[] | undefined;
}

export const CartTable: FC<CartTableProps> = ({ cart }) => {
  const totalSum = cart?.reduce(
    (prev: any, acc: any) => +acc.product_price * acc.amount + prev,
    0
  );

  return (
    <div className="cart block">
      <div className="container">
        <table className="cart__table cart-table">
          <thead className="cart-table__head">
            <tr className="cart-table__row">
              <th className="cart-table__column cart-table__column--image">
                Малюнок
              </th>
              <th className="cart-table__column cart-table__column--product">
                Товар
              </th>
              <th className="cart-table__column cart-table__column--price">
                Ціна
              </th>
              <th className="cart-table__column cart-table__column--quantity">
                Кількість
              </th>
              <th className="cart-table__column cart-table__column--total">
                Всього
              </th>
              <th className="cart-table__column cart-table__column--remove" />
            </tr>
          </thead>
          <tbody className="cart-table__body">
            {cart
              ?.sort((item) => {
                return new Date(item.added_to_cart).getTime();
              })
              .map((cartItem) => (
                <CartTableRow key={cartItem.product_id} item={cartItem} />
              ))}
          </tbody>
        </table>
        <div className="cart__actions">
          {/* leave it as it is for fixing space issue */}
          <div className="cart__coupon-form" />
          {/* ----- */}
          <div className="cart__buttons">
            <Link href="/" className="btn btn-light">
              Продовжити перегляд товарів
            </Link>
          </div>
        </div>
        <div className="row justify-content-end pt-5">
          <div className="col-12 col-md-7 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Сума кошика</h3>
                <table className="cart__totals">
                  <tfoot className="cart__totals-footer">
                    <tr>
                      <th>Всього</th>
                      <td>
                        {formatPriceWithSpaces(totalSum)} {PriceSigns.uah}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <Link
                  className="btn btn-primary btn-xl btn-block cart__checkout-button"
                  href="/checkout"
                >
                  Перейти до оформлення
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
