import { FC, useState } from "react";
import NextImage from "next/image";
import Slider, { Settings } from "react-slick";
import { Item, useGallery } from "react-photoswipe-gallery";
import classNames from "classnames";

import { ProductDetailsProps } from "@/types";

interface ProductGalleryProps {
  product: ProductDetailsProps;
}

export const ProductGallery: FC<ProductGalleryProps> = ({ product }) => {
  const { open } = useGallery();

  const [nav1, setNav1] = useState<Slider | null>();
  const [nav2, setNav2] = useState<Slider | null>();

  const [activeSlide, setActiveSlide] = useState(0);

  const sliderSettingsPreview: Settings = {
    dots: false,
    slidesToShow: 1,
    infinite: true,
  };

  const sliderSettingsItems: Settings = {
    dots: false,
    slidesToShow: product.images.length >= 5 ? 5 : product.images.length,
    infinite: true,
    beforeChange: (_: number, value: number) => {
      setActiveSlide(value);
    },
  };

  return (
    <>
      <div className="product__gallery">
        <div className="product-gallery">
          <div className="product-gallery__featured">
            <button
              className="product-gallery__zoom"
              onClick={() => {
                open(activeSlide);
              }}
            >
              <svg width="24px" height="24px">
                <use xlinkHref="/images/sprite.svg#zoom-in-24" />
              </svg>
            </button>
            <Slider
              {...sliderSettingsPreview}
              ref={(slider2) => setNav2(slider2)}
              asNavFor={nav1!}
            >
              {product?.images.map((img) => (
                <div
                  key={img.image_type}
                  className={classNames(
                    "product-image product-image--location--gallery"
                  )}
                >
                  <NextImage
                    height={490}
                    width={490}
                    className="product-image__img"
                    src={`https://vodotech.com.ua${img.image}`}
                    alt={img.image_type}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="product-gallery__carousel">
            <Slider
              {...sliderSettingsItems}
              ref={(slider1) => setNav1(slider1)}
              asNavFor={nav2!}
            >
              {product?.images.map((img, i) => (
                <div
                  key={img.image_type}
                  onClick={() => {
                    nav1?.slickGoTo(i);
                    setActiveSlide(i);
                  }}
                  className={classNames(
                    "product-image product-gallery__carousel-item",
                    i === activeSlide &&
                      "product-gallery__carousel-item--active"
                  )}
                >
                  <div className="product-image__body">
                    <NextImage
                      width={110}
                      height={110}
                      className="product-image__img product-gallery__carousel-image"
                      src={`https://vodotech.com.ua${img.image}`}
                      alt={img.image_type}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* react-photoswipe-gallery items should be hidden because it renders own slider */}
      <div style={{ display: "none" }}>
        {product?.images.map((img, i) => (
          <Item
            original={`https://vodotech.com.ua${img.image}`}
            width={1200}
            height={1200}
            key={i}
          >
            {({ ref, open }) => (
              <div onClick={open}>
                <img ref={ref} src={`https://vodotech.com.ua${img.image}`} />
              </div>
            )}
          </Item>
        ))}
      </div>
    </>
  );
};
