export enum PaymentMethods {
  ONLINE_PAYMENT = "",
  BY_DETAILS_PAYMENT = "by_details_payment",
  NP_AFTER_PAY = "np_after_pay",
}

export interface AreaProps {
  name: string;
  region_type: string;
  ref: string;
}

export interface SettlementProps {
  ref: string;
  type: string;
  name: string;
}

export interface WarehouseTypeProps {
  name: string;
  ref: string;
}

export interface WarehouseProps {
  id: number;
  warehouse_number: string;
  address: string;
  warehouse_type: WarehouseTypeProps;
}

export interface CartFormProps {
  area: null | any;
  district: null | any;
  settlement: null | any;
  warehouse: null | any;
  first_name: string;
  second_name: string;
  phone_number: string;
  payment_method: string;
  notes?: string;
  checkoutTerms: false;
}
