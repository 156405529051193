import React, { FC } from "react";
import { toast } from "react-toastify";

import { useMutate } from "@/hooks";
import { SavedAddressProps } from "@/types";

interface DeliveryAddressBoxProps {
  address: SavedAddressProps;
  revalidateAddressess: () => void;
}

export const DeliveryAddressBox: FC<DeliveryAddressBoxProps> = ({
  address,
  revalidateAddressess,
}) => {
  const { trigger: deleteAddress } = useMutate(
    `/post/delivery-address/${address.id}/delete/`,
    "DELETE"
  );

  const handleDeleteAddress = async () => {
    try {
      await deleteAddress();
      toast.success("Адресу успішно видалено");
      revalidateAddressess();
    } catch (error) {
      toast.error("Помилка під час видалення");
    }
  };

  return (
    <>
      <div className="addresses-list__divider"></div>
      <div className="addresses-list__item card address-card">
        {/* <div className="address-card__badge">За замовчуванням</div> */}
        <div className="address-card__body">
          <div className="address-card__name">
            {address.first_name} {address.second_name}
          </div>
          <div className="address-card__row">{address.delivery_address}</div>
          <div className="address-card__row">
            <div className="address-card__row-title">Номер телефону</div>
            <div className="address-card__row-content">
              {address.phone_number}
            </div>
          </div>

          <div className="address-card__footer">
            {/* <Link href={`/account/addresses/${address.client}`}>Оновити</Link> */}

            <button
              type="button"
              // className="ml-3"
              style={{ border: "none", outline: "none" }}
              onClick={() => handleDeleteAddress()}
            >
              Видалити
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
