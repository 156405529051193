// import { adminRoutes } from "@/const";
import { useApi } from "@/hooks";
import { CompanyInfoDataProps, ContentInfoType, ContentType } from "@/types";
import Link from "next/link";
// import { useRouter } from "next/router";
import React from "react";

const urls = {
  [ContentInfoType.ABOUT]: "/about-us",
  [ContentInfoType.PRIVACY_POLICY]: "/privacy-policy",
  [ContentInfoType.DELIVERY_RULES]: "/delivery-rules",
};

export const Footer = () => {
  // const router = useRouter();
  const { data } = useApi<CompanyInfoDataProps[]>("/company/info/");

  // const isAdminRoute = adminRoutes.includes(router.pathname);

  return (
    <footer className="site__footer">
      <div className="site-footer">
        <div className="container">
          <div className="site-footer__widgets">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-7">
                <div className="site-footer__widget footer-contacts">
                  <h5 className="footer-contacts__title">Зв'язок з нами</h5>

                  <ul className="footer-contacts__contacts">
                    {data &&
                      data[0].addresses.map((infoAddress) => (
                        <li key={infoAddress.city}>
                          <i className="footer-contacts__icon fas fa-globe-americas" />
                          {infoAddress.city}, {infoAddress.street}{" "}
                          {infoAddress.building}
                        </li>
                      ))}
                    {data &&
                      data[0].phones
                        .filter(
                          (phone) => phone.phone_type !== ContentType.SUPPORT
                        )
                        .map((phone) => (
                          <li key={phone.number}>
                            <i className="footer-contacts__icon fas fa-mobile-alt"></i>
                            <a href={`tel:${phone.number}`}>{phone.number}</a> -{" "}
                            {phone.description}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3">
                <div className="site-footer__widget footer-links">
                  <h5 className="footer-links__title">Інформація</h5>
                  <ul className="footer-links__list">
                    {data &&
                      data[0].infos.map((infoObj) => {
                        return (
                          <li className="footer-links__item" key={infoObj.type}>
                            <Link
                              href={urls[infoObj.type]}
                              className="footer-links__link"
                            >
                              {infoObj.title}
                            </Link>
                          </li>
                        );
                      })}
                    {/* <li className="footer-links__item">
                      <Link href="/about-us" className="footer-links__link">
                        Про нас
                      </Link>
                    </li>
                    <li className="footer-links__item">
                      <Link href="/track-order" className="footer-links__link">
                        Інформація про доставку
                      </Link>
                    </li>
                    <li className="footer-links__item">
                      <Link
                        href="/privacy-policy"
                        className="footer-links__link"
                      >
                        Політика конфіденційності
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-6 col-md-5 col-lg-2">
                <div className="site-footer__widget footer-links">
                  <h5 className="footer-links__title">Акаунт</h5>
                  <ul className="footer-links__list">
                    <li className="footer-links__item">
                      <Link
                        href="/account/orders-history"
                        className="footer-links__link"
                      >
                        Історія замовлень
                      </Link>
                    </li>
                    <li className="footer-links__item">
                      <Link href="/wishlist" className="footer-links__link">
                        Список бажань
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
