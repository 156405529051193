import React, { FC, useCallback, useMemo, useState } from "react";
import Link from "next/link";
import debounce from "lodash.debounce";

import { PriceSigns } from "@/const";
import { CartProduct, ProductCartAction } from "@/types";
import { useUserContext } from "@/context/UserContext";
import { useMutate } from "@/hooks";
import { formatPriceWithSpaces } from "@/utils";

interface CartTableRowProps {
  item: CartProduct;
}

export const CartTableRow: FC<CartTableRowProps> = ({ item }) => {
  const { handleAddToCart: removeFromCart, mutateCartData } = useUserContext();

  const { trigger: updateAmount } = useMutate("/orders/cart/", "POST");

  const {
    product_id,
    product_name,
    product_price,
    product_title_image,
    amount,
  } = item;

  const [selectedAmount, setSelectedAmount] = useState(amount);

  const totalSum = useMemo(
    () => +product_price * selectedAmount,
    [selectedAmount]
  );

  const debounceAddFn = useCallback(
    debounce((prevValue) => {
      updateAmount({
        product_id,
        amount: prevValue - amount,
        option: ProductCartAction.ADD,
      }).then(() => {
        mutateCartData();
      });
    }, 1300),
    [amount]
  );

  const debounceMinusFn = useCallback(
    debounce((updatedAmount, isLessThanMinimum) => {
      if (isLessThanMinimum) {
        updateAmount({
          product_id,
          amount: 1,
          option: ProductCartAction.REMOVE,
        }).then(() => {
          mutateCartData();
        });
      } else {
        updateAmount({
          product_id,
          amount: amount - updatedAmount,
          option: ProductCartAction.REMOVE,
        }).then(() => {
          mutateCartData();
        });
      }
    }, 1300),
    [amount]
  );

  const handleSelectAmount = (add: boolean) => {
    if (add) {
      setSelectedAmount((prevValue) => {
        const updatedAmount = prevValue + 1;
        debounceAddFn(updatedAmount);

        return updatedAmount;
      });
    } else {
      setSelectedAmount((prevValue) => {
        const isLessThanMinimum = selectedAmount <= 1;

        const updatedAmount = isLessThanMinimum ? 1 : prevValue - 1;

        debounceMinusFn(updatedAmount, isLessThanMinimum);

        return updatedAmount;
      });
    }
  };

  return (
    <tr key={product_id} className="cart-table__row">
      <td className="cart-table__column cart-table__column--image">
        <div className="product-image">
          <Link href={`/product/${product_id}`} className="product-image__body">
            <img
              className="product-image__img"
              src={`https://vodotech.com.ua/${product_title_image}`}
              alt={product_name}
            />
          </Link>
        </div>
      </td>
      <td className="cart-table__column cart-table__column--product">
        <Link
          href={`/product/${product_id}`}
          className="cart-table__product-name"
          target="_blank"
        >
          {product_name}
        </Link>
        {/* <ul className="cart-table__options">
      <li>Color: Yellow</li>
      <li>Material: Aluminium</li>
    </ul> */}
      </td>
      <td
        className="cart-table__column cart-table__column--price"
        data-title="Price"
      >
        {formatPriceWithSpaces(+product_price)} {PriceSigns.uah}
      </td>
      <td
        className="cart-table__column cart-table__column--quantity"
        data-title="Quantity"
      >
        <div className="input-number">
          <input
            className="form-control input-number__input"
            type="number"
            min="1"
            value={selectedAmount}
            onChange={() => {}}
          />
          <div
            className="input-number__add"
            onClick={() => handleSelectAmount(true)}
          />
          <div
            className="input-number__sub"
            onClick={() => handleSelectAmount(false)}
          />
        </div>
      </td>
      <td
        className="cart-table__column cart-table__column--total"
        data-title="Total"
      >
        {formatPriceWithSpaces(totalSum)} {PriceSigns.uah}
      </td>
      <td className="cart-table__column cart-table__column--remove">
        <button
          type="button"
          className="btn btn-light btn-sm btn-svg-icon"
          onClick={() => removeFromCart(product_id)}
        >
          <svg width="12px" height="12px">
            <use xlinkHref="images/sprite.svg#cross-12" />
          </svg>
        </button>
      </td>
    </tr>
  );
};
