import axios, { AxiosInstance } from "axios";
import { getSession } from "next-auth/react";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    "Content-Type": "application/json",
    // 'x-advertisement-id': 'some id',
    // 'x-referer': 'some id',
    // ["x-session-id"]: "DFERT87564",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const session = await getSession();

    // all refresh logic happens in [...nextauth].ts

    if (session && session.access) {
      const token = session.access;
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }

    if (!config.headers["x-session-id"]) {
      const cookies = document?.cookie?.split(';')?.reduce((cookies, cookie) => {
        const [key = '', value = ''] = cookie.split('=');
        cookies.set(key, value);
        return cookies;
      }, new Map);
      config.headers["x-session-id"] = cookies.get('x-session-id');
    }

    return config;
  },
  (error) => {
    console.warn(error, "error on client side in axios");
    return Promise.reject(error);
  }
);

export const refreshToken = async (refresh: string) => {
  const { data } = await axiosInstance.post("/client/token/refresh/", {
    refresh,
  });
  return data;
};
