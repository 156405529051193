import { FC } from "react";

interface EmptyDataProps {
  text?: string;
}

export const EmptyData: FC<EmptyDataProps> = ({ text = "Історія пуста" }) => (
  <div className="flex flex-col items-center justify-center">
    <h4 className="text-placeholder font-semibold text-base mt-5 mb-5 text-center">
      {text}
    </h4>
  </div>
);
