import Link from "next/link";
import React from "react";
import Slider, { Settings } from "react-slick";

export const Brands = () => {
  return (
    <div className="block block-brands">
      <div className="container">
        <div className="block-brands__slider">
          <div className="brands-container">
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-1.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-2.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-3.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-4.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-5.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-6.png" alt="" />
              </Link>
            </div>
            <div className="block-brands__item">
              <Link href="/">
                <img src="/images/logos/logo-7.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
