import classNames from "classnames";
import Link from "next/link";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { useRouter } from "next/router";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Breadcrumbs,
  CheckoutProducts,
  EmptyCart,
  ErrorMessage,
  Loader,
} from "@/components";
import { useUserContext } from "@/context/UserContext";
import { useApi, useMutate } from "@/hooks";
import {
  AreaProps,
  CartFormProps,
  PaymentMethods,
  SettlementProps,
  WarehouseProps,
} from "@/types";
import { shopingCartValidation } from "@/const";

const checkoutBreadcrumbs = [
  {
    title: "Головна",
    href: "/",
  },
  {
    title: "Перевірити",
    href: "/checkout",
  },
];

export function CheckoutForm({ profile }: any) {
  const router = useRouter();

  const { mutateCartData, cart, isCartLoading } = useUserContext();

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<CartFormProps>({
    defaultValues: {
      area: undefined,
      district: undefined,
      settlement: undefined,
      warehouse: undefined,
      first_name: profile?.first_name || "",
      second_name: profile?.last_name || "",
      phone_number: profile?.phone || "",
      payment_method: "",
      notes: "",
      checkoutTerms: false,
    },

    resolver: zodResolver(shopingCartValidation),
  });

  const { data: areas, isLoading: isAreasLoading } =
    useApi<AreaProps[]>("/post/areas/");

  const {
    data: districts,
    isLoading: isRegionsLoading,
    mutate: refetchRegions,
  } = useApi<AreaProps[]>(
    getValues("area") ? `/post/regions/${getValues("area")?.value}/` : null
  );

  const {
    data: settlements,
    isLoading: isSettlementsLoading,
    // mutate: refetchSettlements,
  } = useApi<SettlementProps[]>(
    getValues("area")
      ? `/post/settlements/?area_ref=${getValues("area")?.value}`
      : null
  );

  const { data: warehouses, isLoading: isWarehousesLoading } = useApi<
    WarehouseProps[]
  >(
    getValues("settlement")
      ? `/post/warehouses/?settlement_ref=${getValues("settlement")?.value}`
      : null
  );

  const { trigger: createOrder } = useMutate("/orders/create-order/", "POST");

  const onSubmit = async (formValues: any) => {
    try {
      const response: any = await createOrder({
        np_warehouse_id: formValues.warehouse.value,
        first_name: formValues.first_name.trim(),
        second_name: formValues.second_name.trim(),
        phone_number: formValues.phone_number.trim(),
        payment_method: formValues.payment_method,
      });

      if (response.checkout_url) {
        window.open(response.checkout_url, "_blank");
        router.push("/account/orders-history");
      }

      toast.success("Замовлення успішно створено");
      reset();
      mutateCartData();
      router.push("/account/order-completed");
    } catch (error) {
      toast.error("Помилка під створення замовлення");
    }
  };

  watch("area");
  watch("district");
  watch("settlement");
  watch("warehouse");
  watch("payment_method");

  const renderNPForm = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="checkout-country">Область</label>
          <Select
            isClearable
            instanceId="area"
            placeholder="Обрати"
            isLoading={isAreasLoading}
            value={getValues("area")}
            options={areas?.map((area: any) => ({
              value: area.ref,
              label: area.name,
            }))}
            onChange={(val: any) => {
              setValue("area", val);
              refetchRegions();
              clearErrors("area");
              setValue("district", null);
              setValue("settlement", null);
              setValue("warehouse", null);
            }}
          />
          {errors.area?.message && (
            // @ts-expect-error
            <ErrorMessage message={errors.area?.message} />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="checkout-country">Район</label>
          <Select
            isClearable
            instanceId="district"
            placeholder="Обрати"
            isLoading={isRegionsLoading}
            value={getValues("district")}
            isDisabled={!getValues("area")}
            options={districts?.map((district: any) => ({
              value: district.ref,
              label: district.name,
            }))}
            onChange={(val: any) => {
              setValue("district", val);
              clearErrors("district");
              setValue("settlement", null);
              setValue("warehouse", null);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkout-country">Населені пункти</label>
          <Select
            isClearable
            instanceId="settlements"
            placeholder="Обрати"
            isLoading={isSettlementsLoading}
            value={getValues("settlement")}
            isDisabled={!getValues("area")}
            options={settlements?.map((settlement: any) => ({
              value: settlement.ref,
              label: settlement.name,
            }))}
            onChange={(val: any) => {
              setValue("settlement", val);
              clearErrors("settlement");
              setValue("warehouse", null);
            }}
          />
          {errors.settlement?.message && (
            // @ts-expect-error
            <ErrorMessage message={errors.settlement?.message} />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="checkout-country">Склади</label>
          <Select
            isClearable
            instanceId="warehouse"
            placeholder="Обрати"
            isLoading={isWarehousesLoading}
            value={getValues("warehouse")}
            isDisabled={!getValues("settlement")}
            options={warehouses?.map((warehouse) => ({
              value: warehouse.id,
              label: `${warehouse.warehouse_type.name} ${warehouse.address}`,
            }))}
            onChange={(val: any) => {
              setValue("warehouse", val);
              clearErrors("warehouse");
            }}
          />
          {errors.warehouse?.message && (
            // @ts-expect-error
            <ErrorMessage message={errors.warehouse?.message} />
          )}
        </div>
      </>
    );
  };

  const renderDeliveryToAddressForm = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="checkout-street-address">Адреса вулиці</label>
          <input
            type="text"
            className="form-control"
            id="checkout-street-address"
            placeholder="Адреса вулиці"
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkout-address">
            Квартира, блок тощо. <span className="text-muted">(Додатково)</span>
          </label>
          <input type="text" className="form-control" id="checkout-address" />
        </div>
        <div className="form-group">
          <label htmlFor="checkout-city">Місто</label>
          <input type="text" className="form-control" id="checkout-city" />
        </div>
        <div className="form-group">
          <label htmlFor="checkout-postcode">Postcode / ZIP</label>
          <input type="text" className="form-control" id="checkout-postcode" />
        </div>
      </>
    );
  };

  if (isCartLoading)
    return (
      <div className="mt-5">
        <Loader />
      </div>
    );

  if (cart?.length === 0)
    return (
      <div className="mt-5">
        <EmptyCart />
      </div>
    );

  return (
    <>
      <div className="page-header">
        <div className="page-header__container container">
          <div className="page-header__breadcrumb">
            <Breadcrumbs links={checkoutBreadcrumbs} />
          </div>
          <div className="checkout block">
            <div className="container mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12 col-lg-6 col-xl-7">
                  <div className="card mb-lg-0">
                    <div className="card-body">
                      <h3 className="card-title">Оплата</h3>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-first-name">Ім'я</label>
                          <input
                            type="text"
                            className="form-control"
                            id="checkout-first-name"
                            placeholder="Ім'я"
                            {...register("first_name")}
                          />
                          {errors.first_name?.message && (
                            <ErrorMessage
                              message={errors.first_name?.message}
                            />
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-last-name">Прізвище</label>
                          <input
                            type="text"
                            className="form-control"
                            id="checkout-last-name"
                            placeholder="Прізвище"
                            {...register("second_name")}
                          />
                          {errors.second_name?.message && (
                            <ErrorMessage
                              message={errors.second_name?.message}
                            />
                          )}
                        </div>
                      </div>
                      {renderNPForm()}

                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="checkout-phone">Телефон</label>
                          <input
                            type="text"
                            className="form-control"
                            id="checkout-phone"
                            placeholder="Телефон"
                            {...register("phone_number")}
                          />
                          {errors.phone_number?.message && (
                            <ErrorMessage
                              message={errors.phone_number?.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                      <h3 className="card-title">Деталі доставки</h3>

                      <div className="form-group">
                        <label htmlFor="checkout-comment">
                          Примітки до замовлення{" "}
                          <span className="text-muted">(Додатково)</span>
                        </label>
                        <textarea
                          id="checkout-comment"
                          className="form-control"
                          rows={4}
                          {...register("notes")}
                        />
                        {errors.notes?.message && (
                          <ErrorMessage message={errors.notes?.message} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                  <div className="card mb-0">
                    <div className="card-body">
                      <h3 className="card-title">Ваше замовлення</h3>
                      <CheckoutProducts />
                      <div className="payment-methods">
                        <ul className="payment-methods__list">
                          <li
                            className={classNames(
                              "payment-methods__item",
                              getValues("payment_method") ===
                                PaymentMethods.NP_AFTER_PAY &&
                                "payment-methods__item--active"
                            )}
                          >
                            <label
                              className="payment-methods__item-header"
                              style={{ flexWrap: "wrap" }}
                            >
                              <span className="payment-methods__item-radio input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    value={PaymentMethods.NP_AFTER_PAY}
                                    type="radio"
                                    {...register("payment_method")}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              <span className="payment-methods__item-title">
                                При доставці
                              </span>
                              <img
                                style={{ width: "100px", marginLeft: "auto" }}
                                src="/images/np_logo.png"
                                alt="NP"
                              />
                            </label>
                            <div className="payment-methods__item-container">
                              <div className="payment-methods__item-description text-muted">
                                Оплата при доставці у відділені Новой Пошти
                              </div>
                            </div>
                          </li>
                          <li
                            className={classNames(
                              "payment-methods__item",
                              getValues("payment_method") ===
                                PaymentMethods.BY_DETAILS_PAYMENT &&
                                "payment-methods__item--active"
                            )}
                          >
                            <label
                              className="payment-methods__item-header"
                              style={{ flexWrap: "wrap" }}
                            >
                              <span className="payment-methods__item-radio input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    value={PaymentMethods.BY_DETAILS_PAYMENT}
                                    type="radio"
                                    {...register("payment_method")}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              <span className="payment-methods__item-title">
                                За реквізитами
                              </span>
                              <img
                                style={{ width: "100px", marginLeft: "auto" }}
                                src="/images/card_transfer.png"
                                alt="NP"
                              />
                            </label>
                            <div className="payment-methods__item-container">
                              <div className="payment-methods__item-description text-muted">
                                Оплата замовлення на розрахунковий рахунок ФОП
                              </div>
                            </div>
                          </li>
                          {errors.payment_method?.message && (
                            <ErrorMessage
                              message={errors.payment_method?.message}
                            />
                          )}
                        </ul>
                      </div>
                      <div className="checkout__agree form-group">
                        <div className="form-check">
                          <span className="form-check-input input-check">
                            <span className="input-check__body">
                              <input
                                className="input-check__input"
                                type="checkbox"
                                id="checkout-terms"
                                {...register("checkoutTerms")}
                              />
                              <span className="input-check__box" />
                              <svg
                                className="input-check__icon"
                                width="9px"
                                height="7px"
                              >
                                <use xlinkHref="/images/sprite.svg#check-9x7" />
                              </svg>
                            </span>
                          </span>
                          <label
                            className="form-check-label"
                            htmlFor="checkout-terms"
                          >
                            Я прочитав і погоджуюся з{" "}
                            <Link target="_blank" href="/privacy-policy">
                              умовами веб-сайту
                            </Link>
                            *
                          </label>
                          {errors.checkoutTerms?.message && (
                            <ErrorMessage
                              message={errors.checkoutTerms?.message}
                            />
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-xl btn-block"
                        style={{
                          fontSize: "1.06rem",
                        }}
                      >
                        Зробити замовлення
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
