import { UseFormRegister, WatchInternal } from "react-hook-form";

interface RadioButtonProps {
  id: string;
  label: string;
  register: UseFormRegister<any>;
  watch?: WatchInternal<string>;
  value: any;
}

export function RadioButton({
  id,
  register,
  watch,
  label,
  value,
}: RadioButtonProps) {
  watch && watch(id);

  return (
    <label className="filter-list__item ">
      <span className="filter-list__input input-radio">
        <span className="input-radio__body">
          <input
            className="input-radio__input"
            type="radio"
            value={value}
            {...register(id)}
          />
          {<span className="input-radio__circle" />}
        </span>
      </span>
      <span
        className="filter-list__title"
        style={{
          textTransform: "capitalize",
        }}
      >
        {label}
      </span>
    </label>
  );
}
