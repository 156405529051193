import React, { FC } from "react";

import styles from "./styles.module.css";

interface LoaderProps {
  style?: any;
}

export const Loader: FC<LoaderProps> = ({ style = {} }) => (
  <div style={style} className={styles.loader} />
);
