import { FC, Fragment } from "react";

export const CreateComponentsWithLenghtOf: FC<any> = ({
  Component,
  numberOfComponents,
}) => {
  const componentsArray = Array.from(
    { length: numberOfComponents },
    (_, index) => index
  );

  const generateComponents = () => {
    return componentsArray.map((index) => (
      <Fragment key={index}>{Component}</Fragment>
    ));
  };

  return <>{generateComponents()}</>;
};
